import React, { useEffect, useState } from 'react';
import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import { Row, Col, Grid, Card } from 'antd';
import GaugeChart from 'react-gauge-chart';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import classes from './style.module.scss';

am4core.useTheme(am4themes_animated);
const { useBreakpoint } = Grid;
const pieOptions = {
  plugins: {
    legend: {
      position: 'right',
    },
  },
};

const DatausageForecast = () => {
  const { md } = useBreakpoint();
  const { auth: { profile } } = useAuth();
  const [totalDataAmount, setTotalDataAmount] = useState<any>(100);
  const [usedDataAmount, setUsedDataAmount] = useState<any>(0);
  const [forecastedDataAmount, setForecastedDataAmount] = useState<any>(0);

  useEffect(() => {
    if (profile) {
      Api.DATA_USAGE_FORECAST().then((res: any) => {
        const usage = JSON.parse(res.text);
        const dataLimit = usage.usage_limitation;
        const usedData = usage.current_usage;
        const forecastedData = usage.usage_forecast;
        setTotalDataAmount(dataLimit);
        if (usedData) setUsedDataAmount(usedData);
        setForecastedDataAmount(forecastedData);
      });
    }
  }, [profile]);

  const checkDataUnit = (size: number) => {
    if (size == -1) {
      return 'Unlimited';
    }
    let dataString = '0B';
    if (size > 1000000000) {
      const value = (size / 1000000000).toFixed(1);
      dataString = value.concat('GB');
    } else if (size > 1000000) {
      const value = (size / 1000000).toFixed(1);
      dataString = value.concat('MB');
    } else if (size > 1000) {
      const value = (size / 1000).toFixed(1);
      dataString = value.concat('KB');
    } else if (size > 1) {
      dataString = `${size}B`;
    }
    return dataString;
  };

  return (
    <Row align="middle">
      <Col md={12} xs={24} style={{ padding: 8 }}>
        <div style={{ marginLeft: 20 }}>
          <p className={classes.underlined}>Current month limit</p>
          <p className={classes.highlighted}>{checkDataUnit(totalDataAmount)}</p>
          <p className={classes.underlined}>Current month usage</p>
          <p className={classes.highlighted}>{checkDataUnit(usedDataAmount)}</p>
          <p className={classes.underlined}>Forecasted month end usage</p>
          <p className={classes.highlighted}>{checkDataUnit(forecastedDataAmount)}</p>
        </div>
      </Col>
      <Col md={12} xs={24} style={{ padding: 8 }}>
        <GaugeChart id="gauge-data-usage" nrOfLevels={20} percent={totalDataAmount > 0 ? usedDataAmount / totalDataAmount : 0} textColor="black" />
      </Col>
    </Row>
  );
};

export default DatausageForecast;
