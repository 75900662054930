import React, { useEffect, useState } from 'react';

function TypeComponent(props) {
  const { detail } = props;
  if (detail.version == 'A') {
    return <p>Standard</p>;
  }
  if (detail.version == 'B') {
    return <p>Mini</p>;
  }
  if (detail.version == 'MC2') {
    return <p>MC2</p>;
  }
  return <p>DataCam</p>;
}

export default TypeComponent;
