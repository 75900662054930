export const optionsBig = {
  CameraMode: [
    { value: 1, label: 'Photo (Default)' },
    { value: 2, label: 'Video' },
    { value: 3, label: 'PIC+Video' },
  ],

  MultiShot: [
    { value: 1, label: '1 Picture (default)' },
    { value: 2, label: '2 Pictures' },
    { value: 3, label: '3 Pictures' },
    { value: 4, label: '4 Pictures' },
    { value: 5, label: '5 Pictures' },
  ],

  PictureSize: [
    { value: 3, label: '5M (default)' },
    { value: 2, label: '8M' },
    { value: 1, label: '12M' },
  ],

  PirSensitivity: [
    { value: 0, label: 'High (default)' },
    { value: 1, label: 'Middle' },
    { value: 2, label: 'Low' },
  ],

  Cellular: [
    { value: 0, label: 'Once a Day (default)' },
    { value: 1, label: 'Always Available' },
  ],

  NightMode: [
    { value: 1, label: 'Max.Range' },
    { value: 2, label: 'Balanced (default)' },
    { value: 3, label: 'Min.Blur' },
  ],
};
