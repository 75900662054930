import { FieldTimeOutlined, FileImageOutlined, MailOutlined, MinusOutlined } from '@ant-design/icons';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, ThemeProvider } from '@mui/material';
import { Button, Input, notification, Select, Spin } from 'antd';
import * as Api from 'api';
import ProfileNotificationChecklist from 'components/profile/profileNotificationChecklist';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import outerTheme from 'theme/mui';
import classes from './style.module.scss';

type OptionType = {
  value: number;
  label: string;
};

const options: OptionType[] = [
  { value: 0, label: 'None' },
  { value: 1, label: 'Daily' },
  { value: 2, label: 'Weekly' },
  { value: 3, label: 'Monthly' },
];

const optionCategories = [
  { value: 'deer', label: 'Deer Images' },
  { value: 'hog', label: 'Hog Images' },
  { value: 'turkey', label: 'Turkey Images' },
  { value: 'vehicle', label: 'Vehicle Images' },
  { value: 'people', label: 'People Images' },
  { value: 'predator', label: 'Predator Images' },
  { value: 'other', label: 'Other Images' },
];

export interface AdditionalEmailType {
  id: number,
  email: string,
  verify_status: number,
}

export interface AccountType {
  id: number,
  name: string,
  email: string,
  password: string,
}

const ProfileNotification = () => {
  const [reportDuration, setReportDuration] = useState(0);
  const [checkedEmail, setCheckedEmail] = React.useState([false, false, false, false, false, false, false, false]);
  const [checkedPush, setCheckedPush] = React.useState([false, false, false, false, false, false, false, false]);
  const [loadingAdditional, setLoadingAdditional] = useState(false);
  const [additionalEmail1, setAdditionalEmail1] = useState<AdditionalEmailType>({ id: 0, email: '', verify_status: 0 });
  const [additionalEmail2, setAdditionalEmail2] = useState<AdditionalEmailType>({ id: 0, email: '', verify_status: 0 });
  const [additionalEmail3, setAdditionalEmail3] = useState<AdditionalEmailType>({ id: 0, email: '', verify_status: 0 });

  const { auth: { profile } } = useAuth();
  useEffect(() => {
    if (profile) {
      getAdditionalEmails();
      // get cameras and tags

      Api.PROFILE_GET_USER().then(((res: any) => {
        console.log(res.text);
        const data = JSON.parse(res.text);
        // setAccount(data);
        initValues(data);
        // form.setFieldsValue(data);
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [profile]);

  const initValues = (data) => {
    setReportDuration(parseInt(data.email_report, 10));
    console.log(data);
    const newEmailCategories: boolean[] = [];
    if (data.email_ftp_deer == '1') {
      newEmailCategories.push(true);
      newEmailCategories.push(true);
    } else if (data.email_ftp_deer == '2') {
      newEmailCategories.push(true);
      newEmailCategories.push(false);
    } else if (data.email_ftp_deer == '3') {
      newEmailCategories.push(false);
      newEmailCategories.push(true);
    } else {
      newEmailCategories.push(false);
      newEmailCategories.push(false);
    }

    newEmailCategories.push(data.email_ftp_hog == '1');
    newEmailCategories.push(data.email_ftp_turkey == '1');
    newEmailCategories.push(data.email_ftp_people == '1');
    newEmailCategories.push(data.email_ftp_vehicle == '1');
    newEmailCategories.push(data.email_ftp_predator == '1');
    newEmailCategories.push(data.email_ftp_other == '1');

    setCheckedEmail(newEmailCategories);

    //
    const newPushCategories: boolean[] = [];
    if (data.push_deer == '1') {
      newPushCategories.push(true);
      newPushCategories.push(true);
    } else if (data.push_deer == '2') {
      newPushCategories.push(true);
      newPushCategories.push(false);
    } else if (data.push_deer == '3') {
      newPushCategories.push(false);
      newPushCategories.push(true);
    } else {
      newPushCategories.push(false);
      newPushCategories.push(false);
    }

    newPushCategories.push(data.push_hog == '1');
    newPushCategories.push(data.push_turkey == '1');
    newPushCategories.push(data.push_people == '1');
    newPushCategories.push(data.push_vehicle == '1');
    newPushCategories.push(data.push_predator == '1');
    newPushCategories.push(data.push_other == '1');
    setCheckedPush(newPushCategories);
  };

  const verifyEmail1 = () => {
    httpAdditionalVerify(additionalEmail1.email);
  };

  const verifyEmail2 = () => {
    httpAdditionalVerify(additionalEmail2.email);
  };

  const verifyEmail3 = () => {
    httpAdditionalVerify(additionalEmail3.email);
  };

  const deleteEmail1 = () => {
    httpAdditionalDelete(additionalEmail1.id);
  };

  const deleteEmail2 = () => {
    httpAdditionalDelete(additionalEmail2.id);
  };

  const deleteEmail3 = () => {
    httpAdditionalDelete(additionalEmail3.id);
  };

  const onChangeDuration = (value) => {
    setReportDuration(value);
    Api.PROFILE_REPORT_DURATION({ user_id: profile.user_id, value }).then(((res: any) => {
      notification.open({
        message: res.text,
      });
    }));
  };

  const onChangeEmailCategory = (checkedValues) => {
    console.log(checkedValues);
    const params = {
      user_id: profile.user_id,
      email_ftp_deer: checkedValues.deer,
      email_ftp_hog: checkedValues.hog,
      email_ftp_turkey: checkedValues.turkey,
      email_ftp_vehicle: checkedValues.vehicle,
      email_ftp_people: checkedValues.people,
      email_ftp_predator: checkedValues.predator,
      email_ftp_other: checkedValues.other,
    };

    Api.PROFILE_REPORT_EMAIL_CATEGORIES(params).then(((res: any) => {
      notification.open({
        message: res.text,
      });
    }));
  };

  const onChangePushCategory = (checkedValues) => {
    console.log(checkedValues);
    const params = {
      user_id: profile.user_id,
      push_deer: checkedValues.deer,
      push_hog: checkedValues.hog,
      push_turkey: checkedValues.turkey,
      push_vehicle: checkedValues.vehicle,
      push_people: checkedValues.people,
      push_predator: checkedValues.predator,
      push_other: checkedValues.other,
    };

    Api.PROFILE_REPORT_PUSH_CATEGORIES(params).then(((res: any) => {
      notification.open({
        message: res.text,
      });
    }));
  };

  function httpAdditionalVerify(email) {
    const params = {
      user_id: profile.user_id,
      email,
    };

    Api.PROFILE_ADDITIONAL_VERIFY(params).then(((res: any) => {
      notification.open({
        message: res.text,
      });
      getAdditionalEmails();
    }));
  }

  function httpAdditionalDelete(id) {
    const params = {
      user_id: profile.user_id,
      id,
    };

    Api.PROFILE_ADDITIONAL_DELETE(params).then(((res: any) => {
      notification.open({
        message: res.text,
      });
      getAdditionalEmails();
    }));
  }

  const getAdditionalEmails = () => {
    setLoadingAdditional(true);
    Api.PROFILE_GET_ADDITIONAL_EMAILS().then(((res: any) => {
      console.log('getAdditionalEmails', res.text);
      const data = JSON.parse(res.text);
      setAdditionalEmail1({ id: 0, email: '', verify_status: 0 });
      setAdditionalEmail2({ id: 0, email: '', verify_status: 0 });
      setAdditionalEmail3({ id: 0, email: '', verify_status: 0 });
      for (let i = 0; i < data.length; i++) {
        if (i == 0) { setAdditionalEmail1(data[i]); }
        if (i == 1) { setAdditionalEmail2(data[i]); }
        if (i == 2) { setAdditionalEmail3(data[i]); }
      }
      setLoadingAdditional(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <div>
      <ThemeProvider theme={outerTheme}>
        <div className={classes.profileItem}>
          <strong style={{ marginTop: 16 }}><FileImageOutlined />&nbsp;New Image Notifications</strong>
          <p className="text-muted">Please select which images you would like to receive in your email inbox or push notification, when detected at you camera(s)</p>
          <ProfileNotificationChecklist title="Email" values={checkedEmail} onChange={onChangeEmailCategory} />
          <ProfileNotificationChecklist title="Push (Mobile Apps)" values={checkedPush} onChange={onChangePushCategory} />
          <div
            className={classes.divControl}
          />
          <strong><FieldTimeOutlined />&nbsp;Activity Report Email</strong>
          <p className="text-muted">
            Please select if you would like a summary of the activity at your camera(s) emailed to you on a daily, weekly or monthly basis.
          </p>
          <Select
            className={classes.control}
            size="large"
            onChange={onChangeDuration}
            options={options}
            value={reportDuration}
          />
          <div
            className={classes.divControl}
          />
          <strong><MailOutlined />&nbsp;Additional Emails</strong>
          <p className="text-muted">
            Please add more emails for activity report. You can add 3 additional emails.
            Please verify additional emails, we will send to only verified emails.
          </p>
          {/* {JSON.stringify(additionalEmail1)} */}
          <Spin spinning={loadingAdditional} size="large">
            <div
              className={classes.divControl}
            >
              <Button onClick={deleteEmail1} disabled={additionalEmail1.id == 0}><MinusOutlined /></Button>
              <Input
                name="email1"
                value={additionalEmail1.email}
                disabled={additionalEmail1.id != 0}
                onChange={(e) => { setAdditionalEmail1({ id: 0, email: e.target.value, verify_status: 0 }); }}
              />
              {additionalEmail1.verify_status ? <span>Verified</span> : <Button onClick={verifyEmail1}>Verify</Button>}
            </div>
            <div
              className={classes.divControl}
            >
              <Button onClick={deleteEmail2} disabled={additionalEmail2.id == 0}><MinusOutlined /></Button>
              <Input
                name="email2"
                value={additionalEmail2.email}
                disabled={additionalEmail2.id != 0}
                onChange={(e) => { setAdditionalEmail2({ id: 0, email: e.target.value, verify_status: 0 }); }}
              />
              {additionalEmail2.verify_status ? <span>Verified</span> : <Button onClick={verifyEmail2}>Verify</Button>}
            </div>
            <div
              className={classes.divControl}
            >
              <Button onClick={deleteEmail3} disabled={additionalEmail3.id == 0}><MinusOutlined /></Button>
              <Input
                name="email3"
                value={additionalEmail3.email}
                disabled={additionalEmail3.id != 0}
                onChange={(e) => { setAdditionalEmail3({ id: 0, email: e.target.value, verify_status: 0 }); }}
              />
              {additionalEmail3.verify_status ? <span>Verified</span> : <Button onClick={verifyEmail3}>Verify</Button>}
            </div>
          </Spin>
        </div>
      </ThemeProvider>
    </div>
  );
};

export default ProfileNotification;
