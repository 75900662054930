import React, { useEffect, useState } from 'react';

function AttComponent(props) {
  console.log('image', props);
  const { detail } = props;
  if (detail.att_verizon == 'ATT') {
    return <img style={{ height: 15 }} alt="test" src="/icons_camera/carrier_logo_att.png" />;
  }
  if (detail.att_verizon == 'Verizon' || detail.att_verizon == 'VZ + Hologram') {
    return <img style={{ height: 15 }} alt="test" src="/icons_camera/carrier_logo_verizon.png" />;
  }
  if (detail.att_verizon == 'Hologram') {
    return <img style={{ height: 24 }} alt="test" src="/icons_camera/carrier_logo_hologram.png" />;
  }
  return <img style={{ height: 15 }} alt="test" src="/icons_camera/carrier_logo_att.png" />;
}
export default AttComponent;
