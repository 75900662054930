/* eslint-disable react/style-prop-object */
import React, { useEffect, useState } from 'react';
import { Card } from 'antd';
import { VictoryArea, VictoryAxis, VictoryChart, VictoryLegend, VictoryTheme } from 'victory';
import * as Res from 'res';
import 'antd/dist/antd.min.css';
import classes from './style.module.scss';

interface props {
  record: any;
  index: Number;
}

function dayOfWeekAsString(arg: string) {
  // eslint-disable-next-line no-var
  // eslint-disable-next-line radix
  const argValue = parseInt(arg) - 1;
  return ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'][argValue];
}

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
  'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

function getMonthName(arg: string) {
  // eslint-disable-next-line radix
  const argValue = parseInt(arg) - 1;
  if (argValue < 0 || argValue == null) return '';
  return monthNames[argValue];
}

// eslint-disable-next-line no-empty-pattern
const ForecastPredictionChart: React.FC<props> = ({ record, index }) => {
  const [maxY, setMaxY] = useState(0);
  const [minY, setMinY] = useState(10000);
  const [dataSets, setDatasets] = useState<any>([]);
  const [chartLabel, setChartLabel] = useState<string>('');

  function getSunTime(arg: string) {
    const data = arg.split(' ');
    const timeArr = data[0].split(':');
    let value = parseInt(timeArr[0], 10) + parseFloat(timeArr[1]) / 60;
    if (data[1] == 'PM') {
      value += 12;
    }
    value = parseFloat(value.toFixed(2)) / 3;
    return value;
  }

  useEffect(() => {
    const timeSunrise = getSunTime(record.sunrise);
    const timeSunset = getSunTime(record.sunset);

    console.log('key', index);
    const newDatasets: any[] = [];
    let arrayPredict: any[] = [];
    let max = 0;
    let min = 10000;
    let maxIndex = 0;
    for (let i = 0; i < record.data_hourly.length; i++) {
      const item = Number(record.data_hourly[i]);
      arrayPredict.push({ x: i, y: item });
      if (item > max) {
        max = item;
        maxIndex = i;
      }
      if (item < min) {
        min = item;
      }
      if (i <= timeSunrise && i + 1 > timeSunrise) {
        if (i + 1 < record.data_hourly.length) {
          const sunValue = Number(item) + Number(record.data_hourly[i + 1] - item) * (timeSunrise - i);
          arrayPredict.push({ x: timeSunrise, y: sunValue });
          newDatasets.push({
            day_night: 0,
            data: arrayPredict,
          });
          arrayPredict = [];
          arrayPredict.push({ x: timeSunrise, y: sunValue });
        }
      }
      if (i <= timeSunset && i + 1 > timeSunset) {
        if (i + 1 < record.data_hourly.length) {
          const sunValue = item + (record.data_hourly[i + 1] - item) * (timeSunset - i);
          arrayPredict.push({ x: timeSunset, y: sunValue });
          newDatasets.push({
            day_night: 1,
            data: arrayPredict,
          });
          arrayPredict = [];
          arrayPredict.push({ x: timeSunset, y: sunValue });
        }
      }
    }
    if (arrayPredict.length > 1) {
      newDatasets.push({
        day_night: 0,
        data: arrayPredict,
      });
      arrayPredict = [];
    }
    setMaxY(max);
    setMinY(min);
    setDatasets(newDatasets);
    const labels = ['12AM~3AM', '3AM~6AM', '6AM~9AM', '9AM~12PM', '12PM~3PM', '3PM~6PM', '6PM~9PM', '9PM~12AM'];
    setChartLabel(labels[maxIndex]);
  }, [record]);

  return (
    <Card>
      <div className={classes.divHeader}>
        <div className={classes.divDay}>
          <h5 className={classes.firstWeekday}>{dayOfWeekAsString(record.weekday)}</h5>
          <h5>{getMonthName(record.month)}/{record.day}</h5>
        </div>
        <div>
          {/* <img src={`/icons_moon_phase/${record.astro.moon_phase}.png`} alt="" className={classes.img_moon} />
          <h5>{record.astro.moon_phase}</h5> */}
          <h5 className={classes.best_time}>Best Time</h5>
          <h5>{chartLabel}</h5>
        </div>
      </div>
      <Card bodyStyle={{ padding: '8px' }}>
        <VictoryChart
          theme={VictoryTheme.material}
          padding={{ top: 8, bottom: 24, left: 16, right: 16 }}
          maxDomain={{ y: maxY + (maxY - minY) / 2 }}
          minDomain={{ y: minY - (maxY - minY) }}
          height={200}
        >
          <svg style={{ height: 0 }}>
            <defs>
              <linearGradient id="colorDay" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="25%" stopColor={Res.colorBestTime} />
                <stop offset="100%" stopColor="#00000000" />
              </linearGradient>
              <linearGradient id="colorNight" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="25%" stopColor={Res.colorChartNight} />
                <stop offset="100%" stopColor="#00000000" />
              </linearGradient>
            </defs>
          </svg>
          {
            index == 0 && (
              <VictoryLegend
                x={16}
                y={0}
                centerTitle
                orientation="horizontal"
                gutter={20}
                style={{ border: { stroke: 'black', strokeOpacity: 0.5, strokeDasharray: 2 }, title: { fontSize: 4 }, labels: { fontSize: 8 } }}
                data={[
                  { name: 'Day', symbol: { fill: Res.colorBestTime } },
                  { name: 'Night', symbol: { fill: Res.colorChartNight } },
                ]}
              />
            )
          }
          <VictoryAxis
            tickValues={[0, 1, 2, 3, 4, 5, 6, 7]}
            tickFormat={['12AM', '3AM', '6AM', '9AM', '12PM', '3PM', '6PM', '9PM']}
            style={{ tickLabels: { fontSize: 10 } }}
          />
          {dataSets.map((dataset: any, i) => (
            <VictoryArea
              // interpolation="natural"
              key={i}
              style={{
                data: {
                  fill: dataset.day_night == 1 ? 'url(#colorDay)' : 'url(#colorNight)',
                  fillOpacity: 0.3,
                  stroke: dataset.day_night == 1 ? Res.colorBestTime : Res.colorChartNight,
                  strokeWidth: 1,
                },
              }}
              data={dataset.data}
            />
          ))}
        </VictoryChart>
      </Card>
    </Card>
  );
};

export default ForecastPredictionChart;
