import { Button, Card, Col, Collapse, Divider, Form, Input, InputNumber, Modal, Popconfirm, Row, Select, Spin, Switch, TimePicker, Tooltip, notification } from 'antd';
import * as Api from 'api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import classes from './style.module.scss';
import { optionsMC2 } from '../options';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const CameraRemoteMC2Advanced = ({ IMEI, setting, onChange }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [switchPir, setSwitchPir] = useState(true);
  const [workTimer1, setWorkTimer1] = useState(true);
  const [workTimer2, setWorkTimer2] = useState(true);

  useEffect(() => {
    if (setting) {
      let pir_interval_value = setting.pir_interval;
      if (pir_interval_value == 'OFF') {
        setSwitchPir(false);
        pir_interval_value = moment('00:00:00', 'HH:mm:ss');
      } else {
        setSwitchPir(true);
        pir_interval_value = moment(pir_interval_value, 'HH:mm:ss');
      }
      let work_timer_1_value = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
      if (setting.work_timer_1 == 'OFF') {
        setWorkTimer1(false);
      } else {
        setWorkTimer1(true);
        const work_time = setting.work_timer_1.split('-');
        work_timer_1_value = [moment(work_time[0], 'HH:mm'), moment(work_time[1], 'HH:mm')];
      }
      let work_timer_2_value = [moment('00:00', 'HH:mm'), moment('00:00', 'HH:mm')];
      if (setting.work_timer_2 == 'OFF') {
        setWorkTimer2(false);
      } else {
        setWorkTimer2(true);
        const work_time = setting.work_timer_2.split('-');
        work_timer_2_value = [moment(work_time[0], 'HH:mm'), moment(work_time[1], 'HH:mm')];
      }

      form.setFieldsValue({ ...setting, pir_interval_value, work_timer_1_value, work_timer_2_value });
    }
  }, [setting]);

  const onFinish = (values) => {
    const updatedSetting = {};
    for (let [key, value] of Object.entries(values)) {
      if (key == 'work_timer_1_value') {
        key = 'work_timer_1';
        if (value && workTimer1) {
          const time1 = values.work_timer_1_value[0].format(format);
          const time2 = values.work_timer_1_value[1].format(format);
          value = `${time1}-${time2}`;
        } else {
          value = 'OFF';
        }
      } else if (key == 'work_timer_2_value') {
        key = 'work_timer_2';
        if (value && workTimer2) {
          const time1 = values.work_timer_2_value[0].format(format);
          const time2 = values.work_timer_2_value[1].format(format);
          value = `${time1}-${time2}`;
        } else {
          value = 'OFF';
        }
      } else if (key == 'pir_interval_value') {
        key = 'pir_interval';
        if (value && switchPir) {
          value = values.pir_interval_value.format('HH:mm:ss');
        } else {
          value = 'OFF';
        }
      }
      updatedSetting[key] = value;
      key = '';
    }
    onChange(updatedSetting);
  };

  const http_formware_update = () => {
    setIsModalOpen(false);
    const params = {
      IMEI,
    };
    setLoading(true);
    Api.MC2_FIRMWARE_UPDATE(params).then((res: any) => {
      setLoading(false);
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        const date = new Date(data.estimated_time * 1000).toLocaleString();
        const confirm_message = `Your update request has been sent to the camera and should be completed by ${date}. Please check back at that time.`;
        notification.open({
          message: confirm_message,
        });
      } else {
        notification.open({
          message: data.message,
          duration: 10,
        });
      }
    }).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const http_sendcommand = (arg) => {
    const params = {
      IMEI,
      command: arg,
    };
    setLoading(true);
    Api.MC2_SEND_COMMAND(params).then((res: any) => {
      setLoading(false);
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        notification.open({
          message: data.message,
        });
      } else {
        notification.open({
          message: res.text,
          duration: 10,
        });
      }
    }).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  return (
    <Spin spinning={loading} size="large">
      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="Advanced Settings" key="1">
          <Form
            {...layout}
            form={form}
            name="advanced"
            onFinish={onFinish}
          >
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Sending Mode"
                  name="frequency"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsMC2.Frequency}
                  />
                </Form.Item>
                <Form.Item
                  label="Motion Sensor"
                  name="pir_switch"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsMC2.PirSwitch}
                  />
                </Form.Item>
                <Form.Item
                  label="Flash Distance"
                  name="flash_distance"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsMC2.FlashDistance}
                  />
                </Form.Item>
                <Form.Item
                  label="Night Mode"
                  name="night_vision"
                  tooltip={{ title: 'This controls the shutter speed at night. Max Range will illuminate things further away, but it will tend to create more blur. Minimum Blur will reduce the amount of blurriness in the photo, but it will reduce the range of the flash at night. Balanced is good for most situations.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsMC2.NightVision}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Time Lapse"
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="pir_interval_value"
                    >
                      <TimePicker
                        className={classes.control}
                        size="large"
                        disabled={!switchPir}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={switchPir}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setSwitchPir(checked);
                          if (checked) {
                            form.setFieldsValue({ pir_interval_value: moment('00:00:10', 'HH:mm:ss') });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  label="Work Timer1"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="work_timer_1_value"
                    >
                      <TimePicker.RangePicker
                        className={classes.control}
                        size="large"
                        defaultOpenValue={moment('00:00', format)}
                        disabled={!workTimer1}
                        format={format}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={workTimer1}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setWorkTimer1(checked);
                          if (checked) {
                            form.setFieldsValue({ work_timer_1_value: [moment('00:00', 'HH:mm'), moment('00:05', 'HH:mm')] });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  label="Work Timer2"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="work_timer_2_value"
                    >
                      <TimePicker.RangePicker
                        className={classes.control}
                        size="large"
                        defaultOpenValue={moment('00:00', format)}
                        disabled={!workTimer2}
                        format={format}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={workTimer2}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setWorkTimer2(checked);
                          if (checked) {
                            form.setFieldsValue({ work_timer_2_value: [moment('00:00', 'HH:mm'), moment('00:05', 'HH:mm')] });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            <Modal title="" visible={isModalOpen} onOk={http_formware_update} onCancel={() => { setIsModalOpen(false); }}>
              <p>The update may take up to 48hrs to complete. During this time you will be unable to send additional remote control commands. The camera will have to have sufficient battery power and signal for the update to complete properly. After the update, your camera will revert to the default values for all settings. Your camera will continue to send images during the update process. None of your images or data will be affected during the update. Do you wish to proceed?</p>
            </Modal>
            <div className={classes.divFooter}>
              <Button type="primary" style={{ float: 'right' }} htmlType="submit">
                Save
              </Button>
            </div>
          </Form>
          <Divider />
          <div className={classes.divBottom}>
            <div className={classes.divLine}>
              <Button type="primary" onClick={http_formware_update}>
                Camera Firmware Upgrade
              </Button>
            </div>
            <div className={classes.divLine}>
              <Tooltip placement="bottom" title="The reset command can resolve many common issues with the camera. Only use if you are having trouble with your camera. This will remove all of your settings from the camera. Do you wish to proceed?">
                <Popconfirm title="Sure to Reset?" onConfirm={() => http_sendcommand('reset')}>
                  <Button type="primary">
                    Reset
                  </Button>
                </Popconfirm>
              </Tooltip>
            </div>
            <div className={classes.divLine}>
              <Button type="primary" onClick={() => http_sendcommand('format')}>
                SD Format
              </Button>
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </Spin>
  );
};

export default CameraRemoteMC2Advanced;
