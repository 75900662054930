import { FieldTimeOutlined, FileImageOutlined, MailOutlined, MinusOutlined } from '@ant-design/icons';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Slider, Stack, ThemeProvider, Typography } from '@mui/material';
import { Button, Input, notification, Select, Spin } from 'antd';
import * as Api from 'api';
import ProfileNotificationChecklist from 'components/profile/profileNotificationChecklist';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import classes from './style.module.scss';

const ThresholdCardHeader = (props) => {
  const disableUp = (props.index == 0);
  const disableDown = (props.index == 6);

  const marks = [
    {
      value: 1,
      label: 'More',
    },
    {
      value: 3,
      label: 'Default',
    },
    {
      value: 5,
      label: 'Fewer',
    },
  ];

  function valueLabelFormat(value: number) {
    const units = ['KB', 'MB', 'GB', 'TB'];

    let unitIndex = 0;
    let scaledValue = value;

    while (scaledValue >= 1024 && unitIndex < units.length - 1) {
      unitIndex += 1;
      scaledValue /= 1024;
    }

    return `${scaledValue} ${units[unitIndex]}`;
  }

  return (
    <div className={classes.divParent}>
      <span className={classes.textPriority}>
        Priority
      </span>
      <div className={classes.divSlider}>
        <span>
          More
        </span>
        <div className={classes.divSpace} />
        <span>
          Default
        </span>
        <div className={classes.divSpace} />
        <span>
          Fewer
        </span>
      </div>
      <div className={classes.divEnable}>
        <span className={classes.textEnable}>
          Enable
        </span>
      </div>
    </div>
  );
};

export default ThresholdCardHeader;
