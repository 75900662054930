/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CloseOutlined, StopOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Row, Spin } from 'antd';
import * as Api from 'api';
import DashboardHeaderCard from 'components/activity/dashboardHeaderCard';
import { AccountType } from 'components/profile/profileAccount';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuth from 'redux/auth/auth.hook';
import { PATHS } from 'res/routes';
import classes from './style.module.scss';

const DashboardHeader = () => {
  const history = useHistory();
  const { auth: { profile } } = useAuth();
  const [account, setAccount] = useState<any>({});

  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState<any>({
    deer: 0,
    deer_image: 0,
    deer_video: 0,
    hog: 0,
    hog_image: 0,
    hog_video: 0,
    other: 0,
    other_image: 0,
    other_video: 0,
    total: 0,
    turkey: 0,
    turkey_image: 0,
    turkey_video: 0,
  });
  const [cameras, setCameras] = useState<any>({
    total: 0,
    general: 0,
    smart: 0,
  });
  const [limitInfo, setLimitInfo] = useState('');

  useEffect(() => {
    if (profile) {
      // get cameras and tags
      setLoading(true);
      Api.DASHBOARD_HEADER().then((res: any) => {
        const result = JSON.parse(res.text);
        console.log('dashboard_header', result);
        setImages(result.total_image);
        setLimitInfo(result.limit_info);
        setCameras(result.camera_count);
        setLoading(false);
      });
    }
    if (profile) {
      Api.PROFILE_GET_USER().then(((res: any) => {
        console.log(res.text);
        const data = JSON.parse(res.text);
        setAccount(data);
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [profile]);

  const closeLimitInfo = () => {
    setLimitInfo('');
  };

  const clickProfile = () => {
    history.push('/profile');
  };

  return (
    <Spin spinning={loading} size="large">
      <Row>
        {limitInfo &&
          (
            <Col xs={24}>
              <div className={classes.limitInfoDiv}>
                <div className={classes.title}>
                  <span><StopOutlined />&nbsp; Huntcontrol Usage Limitation</span>
                </div>
                <div className={classes.closeBtn} onClick={closeLimitInfo}>
                  <CloseOutlined />
                </div>
                <div className={classes.message}>
                  {limitInfo}
                </div>
              </div>
            </Col>
          )}
        <Col md={6} xs={12}>
          <DashboardHeaderCard type={0} counts={[cameras.total, cameras.smart, cameras.general]} />
        </Col>
        <Col md={6} xs={12}>
          <DashboardHeaderCard type={1} counts={[images.deer, images.deer_image, images.deer_video]} />
        </Col>
        <Col md={6} xs={12}>
          <DashboardHeaderCard type={2} counts={[images.hog, images.hog_image, images.hog_video]} />
        </Col>
        <Col md={6} xs={12}>
          <DashboardHeaderCard type={3} counts={[images.turkey, images.turkey_image, images.turkey_video]} />
        </Col>
      </Row>
    </Spin>
  );
};

export default DashboardHeader;
