/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Card, Tag } from 'antd';
import moment from 'moment';
import { CameraPinDetailType, CameraPinType, CameraSubType } from 'helpers/types';
import { Battery0Bar, Battery1Bar, Battery2Bar, Battery3Bar, Battery4Bar, Battery5Bar, Battery6Bar, BatteryFull, SignalCellular0Bar, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar } from '@mui/icons-material';
import React from 'react';
import { Link } from 'react-router-dom';
import classes from './style.module.scss';

interface props {
  detail: CameraSubType,
}

function BatteryComponent(props) {
  const { detail } = props;
  console.log(detail);
  return detail.battery > 98 ? <BatteryFull /> :
    detail.battery > 75 ? <Battery6Bar /> :
      detail.battery > 50 ? <Battery4Bar /> :
        detail.battery > 25 ? <Battery2Bar /> : <Battery0Bar />;
}

function SignalComponent(props) {
  const { detail } = props;
  console.log(detail);
  if (detail.version == 'A' && detail.csq > 98) {
    return <SignalCellular4Bar />;
  } if (detail.version == 'A' && detail.csq > 75) {
    return <SignalCellular3Bar />;
  } if (detail.version == 'A' && detail.csq > 50) {
    return <SignalCellular2Bar />;
  } if (detail.version == 'A' && detail.csq > 25) {
    return <SignalCellular3Bar />;
  } if (detail.version == 'B' && detail.csq > 4) {
    return <SignalCellular4Bar />;
  } if (detail.version == 'B' && detail.csq > 3) {
    return <SignalCellular3Bar />;
  } if (detail.version == 'B' && detail.csq > 2) {
    return <SignalCellular2Bar />;
  } if (detail.version == 'B' && detail.csq > 1) {
    return <SignalCellular1Bar />;
  }
  return <SignalCellular0Bar />;
}

function TypeComponent(props) {
  const { detail } = props;
  if (detail.version == 'A') {
    return <div className={classes.labelType}>Standard</div>;
  }
  return <div className={classes.labelType}>Mini</div>;
}

function AttComponent(props) {
  const { detail } = props;
  if (detail.att_verizon == 'ATT') {
    return <img className={classes.image} alt="test" src="/icons_camera/carrier_logo_att.png" />;
  }
  return <img className={classes.image} alt="test" src="/icons_camera/carrier_logo_verizon.png" />;
}

const SubCameraCard: React.FC<props> = ({ detail }) => (
  <div style={{ padding: 4 }}>
    <Card
      hoverable
      bodyStyle={{ padding: 8 }}
    >
      <Link to={detail.type == '' || detail.type == null ? `/cameraEdit/${detail.id}` : `/cameraEditPin/${detail.id}`}>
        <div className={classes.divBody}>
          <img className={classes.image} alt="test" src={`/map_pins/${detail.type ? detail.type : 'Camera'}.png`} />
          <div className={classes.divLeft}>
            <div className={classes.divBody}>
              <div className={classes.labelName}>{detail.name} </div>&nbsp;&nbsp;
              <div className={classes.labelIMEI}>{detail.user_name}</div>
            </div>
            {detail.IMEI && <div className={classes.labelDate}>Last Activity: {detail.last_text_uploaded_at > 0 ? moment(detail.last_text_uploaded_at * 1000).format('hh:mm A - MMM D, Y') : ''}</div>}
          </div>
          <div className={classes.divCarrier}>
            {detail.IMEI && <AttComponent detail={detail} />}
            {detail.IMEI && <TypeComponent detail={detail} />}
          </div>
          <div className={classes.divRight}>
            {detail.IMEI && <BatteryComponent detail={detail} />}
            {detail.IMEI && <SignalComponent detail={detail} />}
          </div>
        </div>
      </Link>
    </Card>
  </div>
);

export default SubCameraCard;
