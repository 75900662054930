import React from 'react';
import { Row, Col, Grid, Card } from 'antd';
import DashboardHeader from 'components/activity/dashboardHeader';
import DashboardCameraTable from 'components/activity/dashbaordCameraTable';
import DashboardChartCamera from 'components/activity/dashboardChartCamera';
import DashboardRecently from 'components/activity/dashboardRecently';
import DashboardDataUsage from 'components/activity/dashboardDataUsage';

import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const Dashboard = () => {
  const { md } = useBreakpoint();
  return (
    <>
      <Row>
        <Col md={24} xs={24}>
          <div className={classes.content} style={{ padding: 0 }}>
            <DashboardHeader />
            <Row>
              <Col md={12} xs={24} style={{ padding: 8 }}>
                <Card title="My Cameras" bodyStyle={{ padding: '8px' }}>
                  <DashboardCameraTable />
                </Card>
                <Card title="Recently Uploaded Images" bodyStyle={{ padding: '8px' }} style={{ marginTop: '20px' }}>
                  <DashboardRecently />
                </Card>
              </Col>
              <Col md={12} xs={24} style={{ padding: 8 }}>
                <Card title="Data Usage" bodyStyle={{ padding: '8px' }}>
                  <DashboardDataUsage showLink />
                </Card>
                <Card title="Camera Charts" bodyStyle={{ padding: '8px' }} style={{ marginTop: '20px' }}>
                  <DashboardChartCamera />
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
