import { Col, Grid, Row } from 'antd';
import ImageUploadBox from 'components/image/imageUploadBox';
import ImageUploadCamera from 'components/image/imageUploadCamera';
import React, { useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const ProfilePage = () => {
  const { md } = useBreakpoint();
  const [camera, setCamera] = useState(0);
  const { auth: { profile } } = useAuth();

  const selectCamera = (camera_id) => {
    setCamera(camera_id);
    console.log(camera_id);
  };

  console.log('mount page');
  return (
    <>
      <Row>
        <Col md={6} xs={24}>
          <div style={{ margin: md ? '0 0 0 12px' : '0 8px' }}>
            <ImageUploadCamera selectCamera={(camera_id) => selectCamera(camera_id)} />
          </div>
        </Col>
        <Col md={18} xs={24}>
          <div className={classes.content}>
            <ImageUploadBox camera_id={camera} user_id={profile.user_id} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ProfilePage;
