export const optionsMC2 = {
  Mode: [
    { value: 1, label: 'Picture (default)' },
    { value: 2, label: 'Video' },
    { value: 3, label: 'PIC+Video' },
  ],
  PicSize: [
    { value: 1, label: '5MP (default)' },
    { value: 2, label: '8MP' },
    { value: 3, label: '12MP' },
  ],

  MultiShot: [
    { value: 1, label: '1 Picture (default)' },
    { value: 2, label: '2 Pictures' },
    { value: 3, label: '3 Pictures' },
  ],

  BurstInterval: [
    { value: 1, label: '1s (default)' },
  ],

  VideoSize: [
    { value: 1, label: 'WVGA (default)' },
    { value: 2, label: '720P' },
    { value: 3, label: '1080P' },
  ],

  VideoLength: [
    { value: 1, label: '5S (default)' },
    { value: 2, label: '10s' },
  ],

  VideoLength15: [
    { value: 1, label: '5s (default)' },
    { value: 2, label: '10s' },
    { value: 3, label: '15s' },
  ],

  VideoFps: [
    { value: 1, label: '15FPS (default)' },
    { value: 2, label: '30FPS' },
  ],

  NightVision: [
    { value: 1, label: 'Min.Blur (default)' },
  ],

  FlashDistance: [
    { value: 1, label: 'Near' },
    { value: 2, label: 'Far (default)' },
  ],

  MotionSensitivity: [
    { value: 1, label: '1 (Lowest)' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5 (Default)' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9 (Highest)' },
  ],

  RemoteCtrl: [
    { value: 0, label: '4 Times Daily (default)' },
    { value: 1, label: 'Always On' },
  ],

  Frequency: [
    { value: 0, label: 'Immediately (default)' },
    { value: 1, label: 'Every 1H' },
    { value: 2, label: 'Every 4H' },
  ],

  Transpic: [
    { value: 0, label: 'OFF' },
    { value: 1, label: 'ON (default)' },
  ],

  TransVideo: [
    { value: 1, label: 'Full Video' },
    { value: 2, label: 'Thumbnail Files (default)' },
  ],

  BatteryType: [
    { value: 0, label: 'Alkaline (default)' },
    { value: 1, label: 'Ni-MH' },
  ],

  SdCycle: [
    { value: 0, label: 'OFF' },
    { value: 1, label: 'ON (default)' },
  ],

  PirSwitch: [
    { value: 0, label: 'ON (default)' },
    { value: 1, label: 'OFF' },
  ],
};
