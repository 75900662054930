import React from 'react';

export default function SDCardUsageMC2({ usage, max }) {
  function usageString(data) {
    let dataString = '0MB';
    if (data > 1000000) {
      const value = (data / 1000000).toFixed(1);
      dataString = `${value}TB`;
    } else if (data > 1000) {
      const value = (data / 1000).toFixed(1);
      dataString = `${value}GB`;
    } else if (data > 1) {
      const value = data;
      dataString = `${value}MB`;
    }
    return dataString;
  }

  return (
    <div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
      <div style={{ marginRight: '5px', marginLeft: '5px', textAlign: 'center' }}>
        <img height="40px" alt="test" src="/icons_camera/sdcard_icon.png" />
        <p style={{ margin: 0, color: 'gray' }}>{usageString(usage)}/{usageString(max)}</p>
        <p style={{ margin: 0, color: 'gray' }}>{Math.round((usage * 100) / (max + 0.01))}%</p>
      </div>
    </div>
  );
}
