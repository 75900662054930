import { ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row, Spin, Tag, Tooltip, notification } from 'antd';
import * as Api from 'api';
import BatteryComponentMC2 from 'components/camera/cameraSimbols/BatteryComponentMC2';
import SDCardUsageMC2 from 'components/camera/cameraSimbols/SDCardUsageMC2';
import SignalComponentMC2 from 'components/camera/cameraSimbols/SignalComponentMC2';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import CameraRemoteMC2Advanced from './advanced';
import CameraRemoteMC2Basic from './basic';
import classes from './style.module.scss';

const CameraRemoteMC2 = (props) => {
  const [deviceSetting, setDeviceSetting] = useState<any>(null);
  const [connectStatus, setConnectStatus] = useState<String>('HTTPS');
  const [settingBasic, setSettingBasic] = useState<any>(null);
  const [settingAdvanced, setSettingAdvanced] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    http_load_device_setting(props.camera.IMEI);
  }, [props]);

  useEffect(() => {
    console.log('deviceSetting', deviceSetting);

    if (deviceSetting) {
      const newSettingAdvanced = {
        pir_interval: deviceSetting.pir_interval,
        work_timer_1: deviceSetting.work_timer_1,
        work_timer_2: deviceSetting.work_timer_2,
        frequency: deviceSetting.frequency,
        pir_switch: deviceSetting.pir_switch,
        flash_distance: deviceSetting.flash_distance,
        night_vision: deviceSetting.night_vision,
      };
      setSettingAdvanced(newSettingAdvanced);

      const newSettingBasic = {
        mode: deviceSetting.mode,
        multi_shot: deviceSetting.multi_shot,
        video_length: deviceSetting.video_length,
        video_size: deviceSetting.video_size,
        name: deviceSetting.name,
        motion_sensitivity: deviceSetting.motion_sensitivity,
        transvideo: deviceSetting.transvideo,
        remote_ctrl: deviceSetting.remote_ctrl,
        delay_time: deviceSetting.delay_time,
        pic_size: deviceSetting.pic_size,
      };
      setSettingBasic(newSettingBasic);
    }
  }, [deviceSetting]);

  const onChangeAdvanced = (values) => {
    // console.log('onChangeAdvanced', values);
    setSettingAdvanced(values);
    http_update_device_setting(settingBasic, values);
  };

  const onChangeBasic = (values) => {
    // console.log('onChangeBasic', values);
    setSettingBasic(values);
    http_update_device_setting(values, settingAdvanced);
  };

  const http_update_device_setting = (basic, advanced) => {
    const different_settings = { ...basic, ...advanced };
    console.log('http_update_device_setting', different_settings);
    const params = {
      IMEI: props.camera.IMEI,
      settings: different_settings,
    };
    setLoading(true);
    Api.MC2_SETTING_UPDATE(params).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: 'Your new settings have been sent to your camera and will take effect the next time your camera connects to the server.',
      });
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const http_load_device_setting = (IMEI) => {
    setLoading(true);
    Api.MC2_SETTING_GET(IMEI).then(((data: any) => {
      setLoading(false);
      const res = JSON.parse(data.text);
      console.log(res);
      if (res.result == 'OK') {
        setDeviceSetting(res.setting);
        setConnectStatus(res.connect);
      }
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  function getIndicator(status) {
    if (status == 'Online') {
      return <Tag color="#dc6300">Online</Tag>;
    }
    if (status == 'Offline') {
      return <Tag color="gray">Offline</Tag>;
    }
    return <Tag color="#2db7f5">HTTPS</Tag>;
  }

  return (
    <>
      <Spin spinning={loading} size="large">
        <Card className={classes.card}>
          <div className={classes.divBottomHeader}>
            {deviceSetting && (
              <div className={classes.divFirmware}>
                <Row className={classes.labelFirmware}>
                  <Col md={12} xs={24}>
                    <h1 className={classes.labelTitle}>
                      {props.camera.name}<span> </span>
                      <Tooltip title="Reload the settings">
                        <Button shape="circle" icon={<ReloadOutlined />} onClick={() => http_load_device_setting(props.camera.IMEI)} />
                      </Tooltip>
                    </h1>
                    <p className={classes.pDescription}>Last Synced: {deviceSetting.synced_at > 1000 ? moment(deviceSetting.synced_at * 1000).format('hh:mm A - MMM D, Y') : ''}</p>
                    <p className={classes.pDescription}>Firmware Version: {props.device.firmware_version}</p>
                    <p className={classes.pDescription}>{getIndicator(connectStatus)}</p>
                  </Col>
                  <Col md={12} xs={24}>
                    <div className={classes.divBottomContent}>
                      <div className={classes.divCameraType}>
                        {/* <CameraVersion detail={props.device} size={md ? 100 : 60} /> */}
                      </div>
                      <div className={classes.divImages}>
                        <SDCardUsageMC2 usage={props.device.sd_card} max={props.device.sd_card_max} />
                        <BatteryComponentMC2 battery1={props.device.battery1} battery2={props.device.battery2} />
                        <div className={classes.iconSignal}>
                          <SignalComponentMC2 csq_percent={props.device.csq_percent} fontSize="large" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Card>
        <CameraRemoteMC2Basic IMEI={props.camera.IMEI} setting={settingBasic} onChange={onChangeBasic} />
        <CameraRemoteMC2Advanced IMEI={props.camera.IMEI} setting={settingAdvanced} onChange={onChangeAdvanced} />
      </Spin>
    </>
  );
};

export default CameraRemoteMC2;
