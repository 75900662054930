export const optionsMini2 = {
  CameraMode: [
    { value: 1, label: 'Photo (Default)' },
    { value: 2, label: 'Video' },
    { value: 3, label: 'PIC+Video' },
  ],

  PictureSize: [
    { value: 3, label: '5M (default)' },
    { value: 2, label: '8M' },
    { value: 1, label: '12M' },
  ],

  PirSensitivity: [
    { value: 1, label: '1 (Lowest)' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5' },
    { value: 6, label: '6' },
    { value: 7, label: '7 (Default)' },
    { value: 8, label: '8' },
    { value: 9, label: '9 (Highest)' },
  ],

  Cellular: [
    { value: 0, label: '4 Times Daily (Default)' },
    { value: 1, label: 'Always Available' },
  ],

  MultiShot: [
    { value: 1, label: '1 Picture (Default)' },
    { value: 2, label: '2 Pictures' },
    { value: 3, label: '3 Pictures' },
  ],

  NightMode: [
    { value: 1, label: 'Max.Range' },
    { value: 2, label: 'Balanced (default)' },
    { value: 3, label: 'Min.Blur' },
  ],

  SendingMode: [
    { value: 0, label: 'Instant (Default)' },
    { value: 1, label: 'Every 1H' },
    { value: 2, label: 'Every 4H' },
  ],

  IRFlash: [
    { value: 0, label: 'Far (Default)' },
    { value: 1, label: 'Near' },
  ],

  VideoSending: [
    { value: 1, label: 'Thumbnail Files (Default)' },
    { value: 2, label: 'Full Video' },
  ],

  VideoSize: [
    { value: 1, label: '1080P' },
    { value: 2, label: '720P' },
    { value: 3, label: 'WVGA (Default)' },
  ],

  VideoLength: [
    { value: 5, label: '5s (Default)' },
    { value: 10, label: '10s' },
    { value: 15, label: '15s' },
  ],

  VideoLength1080: [
    { value: 5, label: '5s (Default)' },
    { value: 10, label: '10s' },
  ],
};
