import { Col, Grid, Row } from 'antd';
import bgAuth from 'assets/image/bg-auth.jpg';
import logoPng from 'assets/image/hunt_control_logo.png';
import SignForm from 'components/auth/siginForm';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

interface ParamTypes {
  username: string,
  password: string,
}
const AuthSignInPage = () => {
  const { md } = useBreakpoint();
  const { username, password } = useParams<ParamTypes>();
  const { signRequestSaga } = useAuth();

  console.log('token ==>', username, password);
  useEffect(() => {
    if (username) {
      const name = decodeURIComponent(username);
      const pass = decodeURIComponent(password);
      signRequestSaga({ username: name, password: pass });
    }
  }, [username]);

  return (
    <Row className={classes.authWrapper}>
      {md && (
        <Col md={8}>
          <div className={classes.leftSide}>
            <img className={classes.authBg} src={bgAuth} alt="" />
            <div className={classes.logoWrapper}>
              <img src={logoPng} alt="" />
              <h1>HuntControl</h1>
            </div>

          </div>
        </Col>
      )}
      <Col md={16} xs={24}>
        <div className={classes.rightpanel}>
          <div className={classes.signupFormHeader}>
            <span>Don&apos;t you have an account?</span>
            <a href="/authSignup">Sign up</a>
          </div>
          <div className={classes.siginFormWrapper}>
            <SignForm />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default AuthSignInPage;
