import { PlusCircleOutlined } from '@ant-design/icons';
import { Card, Col, Grid, Row, Spin } from 'antd';
import * as Api from 'api';
import CameraGroupItem from 'components/camera/cameraGroupItem';
import LeftCameraMap from 'components/camera/cameraMap';
import { CameraPinType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const CameraGroup = () => {
  const { md } = useBreakpoint();
  const { auth: { profile } } = useAuth();
  const [cameras, setCameras] = useState<CameraPinType[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  // show group on map
  const [mapGroupName, setMapGroupName] = useState('All');
  const [mapCameras, setMapCameras] = useState<CameraPinType[]>([]);

  useEffect(() => {
    if (profile) {
      loadData();
    }
  }, [profile]);

  const reloadGroupData = () => {
    setLoading(true);
    Api.CAMERA_GROUP_GET_ALL().then(((res: any) => {
      const result = JSON.parse(res.text);
      console.log('camera groups', result);
      setGroups(result);
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const loadData = () => {
    Api.CAMERA_GET_ALL().then(((res: any) => {
      const result = JSON.parse(res.text);
      setCameras(result.cameras);
      setMapCameras(result.cameras);
      console.log('cameras', result.cameras);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
    reloadGroupData();
  };

  const clickAddGroup = () => {
    if (groups.length > 0) {
      const lastGroup = groups[groups.length - 1];
      if (lastGroup.id == 0) {
        return;
      }
    }
    const newGroup = {
      id: 0,
      name: '',
      cameras: [],
    };
    setGroups([...groups, newGroup]);
  };

  const onMapChangeGroup = (gname, gcameras) => {
    setMapGroupName(gname);
    const cameraArr: any[] = [];
    cameras.forEach((item) => {
      if (gcameras.includes(item.id)) {
        cameraArr.push(item);
      }
    });
    setMapCameras(cameraArr);
  };

  return (
    <div className={classes.content}>
      <Row>
        <Col md={12} xs={24} className={classes.leftMap}>
          <div className={classes.mapTitle}>
            <h3>Camera Group : {mapGroupName}</h3>
          </div>
          <LeftCameraMap cameras={mapCameras} />
        </Col>
        <Col md={12} xs={24}>
          <Card title="Camera Groups" style={{ margin: md ? '12px 12px 0 0' : '2px' }}>
            <Spin spinning={loading} size="large">
              <div>
                {groups && groups.map((item, index) =>
                  <CameraGroupItem cameras={cameras} groupData={item} onGropUpdate={reloadGroupData} onFocusGroup={(gname, gcameras) => onMapChangeGroup(gname, gcameras)} key={index} />)}
                <div className={classes.addGroup} onClick={clickAddGroup}>
                  <PlusCircleOutlined /> &nbsp;Add a camera group
                </div>
              </div>
            </Spin>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default CameraGroup;
