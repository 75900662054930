import { AutoComplete, Col, DatePicker, Form, Grid, notification, Popconfirm, Row, Select, Spin, Tooltip } from 'antd';
import * as Api from 'api';
import { ImageType } from 'helpers/types';
import React, { Fragment, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import ImageEditTag from 'components/image/ImageEditTag';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import moment from 'moment';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, IconButton, ThemeProvider } from '@mui/material';
import outerTheme from 'theme/mui';
import StarIcon from '@mui/icons-material/Star';
import { StarBorderOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { saveAs } from 'file-saver';
import useDownloader from 'react-use-downloader';
import classes from './style.module.scss';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';
const { useBreakpoint } = Grid;
const { Option } = Select;

interface props {
  activeIndex: number
  cameraArr: any[]
  onLastPage
  onFavorite: (index, isFavorite) => void;
}

const moonPhaseLabels = ['New Moon', 'Wax. Crescent', '1st Quarter', 'Wax. Gibbous', 'Full Moon', 'Wan. Gibbous', '2nd Quarter', 'Wan. Crescent'];

const GalleryMediaBox: React.FC<props> = ({ activeIndex, cameraArr, onLastPage, onFavorite }) => {
  const [trachCounter, setTrashCounter] = useState(0);
  const { images, filterOptions, setImages, tags, setTags } = useApi();
  const { auth: { profile } } = useAuth();
  const [currentIndex, setCurrentIndex] = useState(activeIndex);
  const [loading, setLoading] = useState(false);
  const [imageDetail, setImageDetail] = useState<ImageType>(images[activeIndex]);
  const [filteredTags, setFilteredTags] = useState<any[]>([]);
  const [tagText, setTagText] = useState('');
  const [mediaType, setMediaType] = useState('');
  const { md, xs } = useBreakpoint();
  const [openHD, setOpenHD] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const { download } = useDownloader();

  console.log('imagedetail', imageDetail);

  const editCategories = [
    'Deer (Antlered)',
    'Deer (Non Antlered)',
    'Hog',
    'Turkey',
    'People',
    'Vehicle',
    'Predator',
    'Other',
  ];

  useEffect(() => {
    console.log(currentIndex);
    if (images.length <= 0 || currentIndex < 0) {
      return;
    }
    const detail = images[currentIndex];
    setMediaType(detail.thumbnail == '' ? 'image' : 'video');
    setImageDetail(detail);
    setTagText(detail.tag);
  }, [currentIndex, images, activeIndex]);

  useEffect(() => {
    updateFilteredTags('');
  }, [tags]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (trachCounter > 0) {
        setTrashCounter(trachCounter - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [trachCounter]);

  const onclickLeftArrow = () => {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      newIndex = 0;
    }
    setCurrentIndex(newIndex);
  };

  const onclickRightArrow = () => {
    const newIndex = currentIndex + 1;
    if (newIndex >= images.length - 1) {
      onLastPage();
    }
    if (newIndex <= images.length - 1) {
      setCurrentIndex(newIndex);
    }
  };

  function moonPhaseIndex(moon) {
    let temp = 0;
    if (moon < 0.02) { temp = 0; } else if (moon >= 0.02 && moon <= 0.23) { temp = 1; } else if (moon > 0.23 && moon < 0.27) { temp = 2; } else if (moon >= 0.27 && moon <= 0.48) { temp = 3; } else if (moon > 0.48 && moon < 0.52) { temp = 4; } else if (moon >= 0.52 && moon <= 0.73) { temp = 5; } else if (moon > 0.73 && moon < 0.77) { temp = 6; } else if (moon >= 0.77 && moon <= 0.98) { temp = 7; }
    return temp;
  }

  function modifyCategory(detail) {
    if (detail.category == 'Deer') {
      return `Deer (${detail.category_sub == 'Antlered' ? 'Antlered' : 'Non Antlered'})`;
    }
    return detail.category;
  }

  const updateFilteredTags = (value) => {
    const res: any = [];
    if (!value || value == '') {
      res.push('-CLEAR-');
      tags.forEach((element) => {
        if (element.tag !== '') { res.push(element.tag); }
      });
    } else {
      let newTag = true;
      tags.forEach((element) => {
        if (element.tag.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
          res.push(element.tag);
        }
        if (element.tag.toUpperCase() == value.toUpperCase()) {
          newTag = false;
        }
      });
      if (newTag) {
        res.push(`-NEW- ${value}`);
      }
      console.log(res);
    }
    setFilteredTags(res);
  };

  const onChangeCamera = (e) => {
    httpEdit('camera_id', e, () => {
      let shouldMove = true;
      if (filterOptions.camera_id == '0' || filterOptions.camera_id == undefined) {
        shouldMove = false;
      }
      const newImages = [...images];
      if (shouldMove) {
        newImages.splice(currentIndex, 1);
        if (currentIndex == images.length - 1) {
          setCurrentIndex(currentIndex - 1);
        }
      } else {
        const cam = cameraArr.find((item) => item.id == e);
        newImages[currentIndex].camera_id = cam.id;
        newImages[currentIndex].camera_name = cam.name;
      }
      setImages(newImages);
    });
  };

  const onChangeCategory = (e) => {
    httpEdit('category', e, () => {
      let shouldMove = false;
      if (filterOptions.category == 'all' || filterOptions.category == undefined) {
        shouldMove = false;
      } else if ((filterOptions.category == 'deer' && filterOptions.antlered == 0)) {
        if (e.includes('Deer')) {
          shouldMove = false;
        } else {
          shouldMove = true;
        }
      } else {
        shouldMove = true;
      }

      const newImages = [...images];
      if (shouldMove) {
        newImages.splice(currentIndex, 1);
        if (currentIndex == images.length - 1) {
          setCurrentIndex(currentIndex - 1);
        }
      } else if (e.includes('Deer')) {
        const arr = e.replace(/[)(]/g, '').split(' ');
        newImages[currentIndex] = { ...newImages[currentIndex], category: arr[0], category_sub: arr[1] ? arr[1] : 'No' };
      } else {
        newImages[currentIndex].category = e;
      }
      setImages(newImages);
    });
  };

  const onChangeTag = (tag, category, type) => {
    console.log(tag, category, type);

    setTagText(tag);
    if (imageDetail.tag != tag) {
      httpEdit('tag', tag, () => {
        let shouldMove = true;
        if (filterOptions.tag == '0' || filterOptions.tag == undefined) {
          shouldMove = false;
        }
        const newImages = [...images];
        if (shouldMove) {
          newImages.splice(currentIndex, 1);
          if (currentIndex == images.length - 1) {
            setCurrentIndex(currentIndex - 1);
          }
        } else {
          newImages[currentIndex].tag = tag;
        }
        setImages(newImages);
        if (type == 2) {
          http_load_tags();
        }

        if (category != 'None') {
          onChangeCategory(category);
        } else {
          notification.open({
            message: 'Your Change was Successful',
          });
        }
      });
    }
  };

  const onChangeFavorite = (e) => {
    console.log(currentIndex);
    onFavorite(currentIndex, imageDetail.favorite == 1 ? 0 : 1);
  };

  const onChangeTakenTime = (date, dateString) => {
    console.log(date, dateString);

    if (dateString == '') {
      return;
    }

    if (images[currentIndex].manually_uploaded == '0') {
      notification.open({
        message: 'Not manually uploaded image',
      });
    } else {
      httpEdit('time', dateString, (res: any) => {
        const result = JSON.parse(res.text);
        console.log('result', result);
        const newImages = [...images];
        newImages[currentIndex] = result;
        setImages(newImages);
      });
    }
  };

  const onTrash = () => {
    setTrashCounter(3);
  };

  const onDownload = (imageUrl, imageName) => {
    saveAs(imageUrl, imageName);
  };

  const onSendHD = () => {
    setOpenHD(false);
    const ids: any = [];
    ids.push(imageDetail.id);

    const params = {
      ids,
      camera_id: imageDetail.camera_id,
    };
    setLoading(true);
    Api.IMAGE_REQUEST_HD_ONE_CAMERA(params).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: res.text,
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const onSendVideo = () => {
    setOpenVideo(false);
    setTrashCounter(0);
    const ids: any = [];
    ids.push(imageDetail.id);

    const params = {
      ids,
      camera_id: imageDetail.camera_id,
    };
    setLoading(true);
    Api.IMAGE_REQUEST_HD_ONE_CAMERA(params).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: res.text,
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const trashConfirm = () => {
    setTrashCounter(0);
    const ids: any = [];
    ids.push(imageDetail.id);

    const params = {
      ids,
    };
    setLoading(true);
    Api.IMAGE_TRASH(params).then((() => {
      setLoading(false);
      const newImages = [...images];
      newImages.splice(currentIndex, 1);
      if (currentIndex == images.length - 1) {
        setCurrentIndex(currentIndex - 1);
      }
      setImages(newImages);
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const httpEdit = (field, value, callback) => {
    const params = {
      image_id: imageDetail.id,
      field,
      value,
    };

    setLoading(true);
    Api.IMAGE_EDIT_ONE(params).then(((res: any) => {
      callback(res);
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  };

  const http_load_tags = () => {
    Api.IMAGE_ALL_TAGS({ user_id: profile.user_id }).then(((res: any) => {
      const tagArr = JSON.parse(res.text);
      setTags(tagArr);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <Spin spinning={loading} size="large">
      <div className={classes.mediaboxWrapper}>
        <div style={{ position: 'relative', width: '100%' }}>
          {mediaType == 'video' && (
            <>
              <div className={classes.imageZoom}>
                <IconButton onClick={() => download(`https://d7s85wyrr26qk.cloudfront.net/${imageDetail.image_name}`, imageDetail.image_name)}>
                  <FileDownloadIcon />
                </IconButton>
              </div>
              <ReactPlayer url={`https://d7s85wyrr26qk.cloudfront.net/${imageDetail.image_name}`} controls width="100%" height="auto" style={{ minWidth: '480px', minHeight: '320px' }} />
            </>
          )}
          {mediaType !== 'video' && (
            <TransformWrapper>
              {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                  <div className={classes.imageZoom}>
                    <IconButton onClick={() => zoomIn(1, 300)}>
                      <ZoomInIcon />
                    </IconButton>
                    <IconButton onClick={() => zoomOut(1, 300)}>
                      <ZoomOutIcon />
                    </IconButton>
                    <IconButton onClick={() => resetTransform()}>
                      <ZoomOutMapIcon />
                    </IconButton>
                    <IconButton onClick={() => download(`https://d7s85wyrr26qk.cloudfront.net/${imageDetail.image_name}`, imageDetail.image_name)}>
                      <FileDownloadIcon />
                    </IconButton>
                  </div>
                  <TransformComponent contentClass={classes.react_transform_component} wrapperClass={classes.react_transform_wrapper}>
                    <img className={classes.image} src={`https://d7s85wyrr26qk.cloudfront.net/${imageDetail.image_name}`} alt="" />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          )}
          <div className={classes.actionBarLeft}>
            <span className={classes.leftArrow} onClick={onclickLeftArrow}>❮</span>
          </div>
          <div className={classes.actionBarRight}>
            <span className={classes.rightArrow} onClick={onclickRightArrow}>❯</span>
          </div>
          <div className={classes.divStar}>
            <ThemeProvider theme={outerTheme}>
              {imageDetail.is_hd != '1' && imageDetail.thumbnail == '' && imageDetail.is_thumbnail != '1' && (
                <div style={{ marginRight: '8px', display: 'inline-block' }}>
                  <Fab size="medium" variant="extended" onClick={() => { setOpenHD(true); }}>
                    <SendIcon sx={{ mr: 1 }} />
                    Request XL Image
                  </Fab>
                  <Dialog
                    open={openHD}
                    onClose={() => { setOpenHD(false); }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Do you wish to continue?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        There is no additional cost to requesting XL versions of your images or full length videos. However, the larger pictures and longer videos will consume more of your monthly data allotment than the normal pictures.
                        Please monitor your data usage page and contact support with any questions.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => { setOpenHD(false); }}>Disagree</Button>
                      <Button onClick={onSendHD} autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              {imageDetail.is_thumbnail == '1' && (
                <div style={{ marginRight: '8px', display: 'inline-block' }}>
                  <Fab size="medium" variant="extended" onClick={() => { setOpenVideo(true); }}>
                    <SendIcon sx={{ mr: 1 }} />
                    Request Video
                  </Fab>
                  <Dialog
                    open={openVideo}
                    onClose={() => { setOpenVideo(false); }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      Do you wish to continue?
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        There is no additional cost to requesting XL versions of your images or full length videos. However, the larger pictures and longer videos will consume more of your monthly data allotment than the normal pictures.
                        Please monitor your data usage page and contact support with any questions.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={() => { setOpenVideo(false); }}>Disagree</Button>
                      <Button onClick={onSendVideo} autoFocus>
                        Agree
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
              <Fab size="medium" aria-label="like" style={{ marginRight: '8px' }} onClick={trashConfirm}>
                <DeleteIcon />
              </Fab>
              <Fab size="medium" color={imageDetail.favorite == 1 ? 'primary' : 'default'} aria-label="like" onClick={onChangeFavorite}>
                {imageDetail.favorite == 1 ? <StarIcon /> : <StarBorderOutlined />}
              </Fab>
            </ThemeProvider>
          </div>
        </div>
        {imageDetail && (
          <div className={classes.cameraInfoText} style={{ height: md ? 60 : 120 }}>
            <Row style={{ height: '100%' }}>
              <Col md={8} xs={24}>
                <div className={classes.infoCol}>
                  <span>
                    {imageDetail.weather_description}
                  </span>
                  <span>
                    {
                      imageDetail.weather_icon == '' ? (<img src={`/icons_weather/${imageDetail.icon}.png`} alt="" />) : (<img src={imageDetail.weather_icon} alt="" />)
                    }
                  </span>
                  <span>{Math.round(imageDetail.temp)}F {imageDetail.rh}%RH {imageDetail.wind_direction} {imageDetail.wind_speed}MPH</span>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <div className={classes.infoCol}>
                  <span>Sun Rise: {imageDetail.sunrise} Set: {imageDetail.sunset}</span>
                </div>
              </Col>
              <Col md={8} xs={12}>
                <div className={classes.infoCol}>
                  <span>
                    {imageDetail.weather_icon == '' ? <img src={`/icons_moon/moon${moonPhaseIndex(imageDetail.moon)}.png`} alt="" /> : <img src={`/icons_moon_phase/${imageDetail.moon_phase}.png`} alt="" />}
                    &nbsp;
                    {imageDetail.weather_icon == '' ? moonPhaseLabels[moonPhaseIndex(imageDetail.moon)] : imageDetail.moon_phase}
                  </span>
                </div>
              </Col>
            </Row>
          </div>
        )}
        <Row className={classes.editDiv}>
          <Col span={xs ? 12 : 6}>
            <Select placeholder="Change Camera To:" onChange={onChangeCamera} value={imageDetail.camera_id} dropdownStyle={{ position: 'fixed' }} className={classes.editField}>
              {cameraArr.map((val: any) => (
                <Option key={val.id} value={val.id}>{val.name}</Option>
              ))}
            </Select>
          </Col>
          <Col span={xs ? 12 : 6}>
            <Select placeholder="Change Category To:" onChange={onChangeCategory} value={modifyCategory(imageDetail)} dropdownStyle={{ position: 'fixed' }} className={classes.editField}>
              {editCategories.map((val, i) => (
                <Option key={i} value={val}>{val}</Option>
              ))}
            </Select>
          </Col>
          <Col span={xs ? 12 : 6}>
            <div className={classes.editField}>
              <Form.Item name="to_tag" style={{ marginBottom: 0 }}>
                <ImageEditTag selectedImage={imageDetail.id} selectedTag={tagText} tags={tags} size="large" onChangeTag={onChangeTag} style={{ width: '100%' }} dropdownStyle={{ position: 'fixed' }} />
              </Form.Item>
            </div>
          </Col>
          {imageDetail.manually_uploaded == '1' && (
            <Col span={xs ? 12 : 6}>
              <div className={classes.editField}>
                <Form.Item name="edit_date" style={{ marginBottom: 0 }}>
                  <DatePicker
                    style={{
                      width: '100%',
                    }}
                    placeholder="Taken Time"
                    showTime
                    defaultValue={moment(imageDetail.time, dateFormat)}
                    onChange={onChangeTakenTime}
                  />
                </Form.Item>
              </div>
            </Col>
          )}
        </Row>
      </div>
    </Spin>
  );
};

export default GalleryMediaBox;
