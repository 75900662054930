import { Col, DatePicker, Form, Input, Radio, Row, Select, Space, Spin } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import moment from 'moment';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const FilterBestTime = (props) => {
  const [form] = Form.useForm();
  const { auth: { profile } } = useAuth();
  const { md } = useBreakpoint();
  const [filterOptions, setFilterOption] = useState({
    camera_id: 0,
    startDate: '',
    endDate: '',
    tag: '',
  });

  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [cameraGroups, setCameraGroups] = useState<any[]>([]);
  const [tagArr, setTagArr] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Api.CAMERAS_GROUPS_TAGS().then(((res: any) => {
      const result = JSON.parse(res.text);
      setCameraArr(result.cameras);
      setCameraGroups(result.cameraGroups);
      setTagArr(result.tags);
      const newFilter = { ...filterOptions, camera_id: 0 };
      setFilterOption(newFilter);
      props.handleFilters(newFilter);
      form.setFieldsValue(newFilter);
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoading(false);
    });
  }, []);

  const onValuesChange = (values, allvalues) => {
    const newFilter = { ...filterOptions, ...allvalues };
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    const newFilter = { ...filterOptions, startDate: dateString[0], endDate: dateString[1] };
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const [value, setValue] = useState(1);

  const onChange = (e) => {
    console.log('radio checked', e.target.value);
    const index = e.target.value;

    let newFilter;
    if (index == 1) {
      newFilter = { ...filterOptions, startDate: '', endDate: '' };
      setFilterOption(newFilter);
    } else if (index == 2) {
      const startDate = moment().add(-1, 'week').format('YYYY-MM-DD');
      newFilter = { ...filterOptions, startDate, endDate: '' };
      setFilterOption(newFilter);
    } else if (index == 3) {
      const startDate = moment().add(-2, 'week').format('YYYY-MM-DD');
      newFilter = { ...filterOptions, startDate, endDate: '' };
      setFilterOption(newFilter);
    } else if (index == 4) {
      const startDate = moment().add(-1, 'month').format('YYYY-MM-DD');
      newFilter = { ...filterOptions, startDate, endDate: '' };
      setFilterOption(newFilter);
    }
    props.handleFilters(newFilter);

    setValue(index);
  };

  return (
    <div className={classes.wrapper}>
      <Spin spinning={loading} size="large">
        <Form form={form} style={{ marginTop: md ? 20 : 0 }} onValuesChange={onValuesChange}>
          <div className={classes.formInputLabel}>Choose Camera:</div>
          <Form.Item name="camera_id" className={classes.formInputWrapper}>
            <Select size="large">
              <Option key={0} value={0}>All</Option>
              <OptGroup label="Cameras">
                {cameraArr.map((val: any) => (
                  <Option key={val.id} value={val.id}>{val.name}</Option>
                ))}
              </OptGroup>
              <OptGroup label="Camera Groups">
                {cameraGroups.map((val: any) => (
                  <Option key={val.id} value={`group${val.id}`}>{val.name}</Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <div className={classes.formInputLabel}>Choose Tag:</div>
          <Form.Item name="tag" className={classes.formInputWrapper}>
            <Select size="large">
              <Option key={0} value="">All</Option> :
              {tagArr.map((val: any) => (
                val.tag != '' && (<Option key={val.tag} value={val.tag}>{val.tag}</Option>)
              ))}
            </Select>
          </Form.Item>
          <div className={classes.formInputLabel}>Date range:</div>
          <Radio.Group className={classes.radioGroup2} onChange={onChange} value={value}>
            <Space direction="vertical">
              <Radio value={1}>All</Radio>
              <Radio value={2}>Last Week</Radio>
              <Radio value={3}>Last 2 Weeks</Radio>
              <Radio value={4}>Last Month</Radio>
              <Radio value={5}>
                Custom
                {value === 5 ? <RangePicker style={{ width: '100%' }} onChange={onChangeDate} /> : null}
              </Radio>
            </Space>
          </Radio.Group>
        </Form>
      </Spin>
    </div>
  );
};

export default FilterBestTime;
