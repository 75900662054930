import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Grid, Modal, Button, Input, notification, Spin, Alert } from 'antd';
import CameraCard from 'components/camera/cameraCard';
import LeftCameraMap from 'components/camera/cameraMap';

import * as Api from 'api';
import useAuth from 'redux/auth/auth.hook';
import { CameraPinDetailType, CameraPinType } from 'helpers/types';

import { PlusOutlined } from '@ant-design/icons';
import { Link, useHistory, useParams } from 'react-router-dom';
import useApi from 'redux/api/api.hook';
import { PATHS } from 'res/routes';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const CameraPage = () => {
  const { md } = useBreakpoint();
  const { auth: { profile } } = useAuth();
  const [cameras, setCameras] = useState<CameraPinDetailType[]>([]);
  const [loading, setLoading] = useState(false);
  const leftMap = useRef<any>();
  const history = useHistory();
  const [low_battey_alert, setLowBatteryAlert] = useState('');

  useEffect(() => {
    if (profile) {
      loadData();
    }
  }, [profile]);

  const loadData = () => {
    setLoading(true);
    Api.MAP_CAMERAS_PINS_DETAIL().then(((res: any) => {
      const result = JSON.parse(res.text);
      setCameras(result.cameras);

      let camera_string = '';
      let camera_more_one = 0;
      result.cameras.forEach((camera) => {
        if (camera.battery1 != '' && parseFloat(camera.battery1) < 8.6 && parseFloat(camera.battery1) > 7) {
          if (camera_string != '') {
            camera_string += ', ';
            camera_more_one = 1;
          }
          camera_string += camera.name;
        }
        console.log('interval voltage', parseFloat(camera.battery1));
      });
      console.log('done');
      if (camera_string != '') {
        if (camera_more_one == 1) {
          setLowBatteryAlert(`The internal battery voltages of ${camera_string} are getting low, we recommend checking the batteries soon.`);
        } else {
          setLowBatteryAlert(`The internal battery voltage of ${camera_string} is getting low, we recommend checking the batteries soon.`);
        }
      }
      setLoading(false);
      if (result.cameras.length == 0) {
        const path = PATHS.CAMERA_ADD;
        history.push(path);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const handleMapBoundChange = () => {
    if (leftMap.current) {
      leftMap.current.handleMapBoundChange();
    }
  };

  return (
    <>
      <Row>
        <Col md={12} xs={24} className={classes.leftMap}>
          <LeftCameraMap cameras={cameras} ref={leftMap} />
        </Col>
        <Col md={12} xs={24}>
          {low_battey_alert != '' && (
            <Alert
              message="Action Required."
              description={low_battey_alert}
              type="warning"
              closable
            />
          )}
          <div className={classes.content} style={{ padding: md ? '12px 12px 12px 0px' : 8 }}>
            <Col xs={24} md={24}>
              <Spin spinning={loading} size="large">
                <Row>
                  {cameras.map((e) => (
                    <Col key={e.id} xs={24} md={24}>
                      <CameraCard detail={e} />
                    </Col>
                  ))}
                </Row>
              </Spin>
            </Col>
          </div>
        </Col>
        <div className={classes.containerFloat}>
          <Link to="/cameraAddPin">
            <Button type="primary" shape="round" size="large" icon={<PlusOutlined />} onClick={handleMapBoundChange}>
              Marker
            </Button>
          </Link>
          <Link to="/cameraAdd">
            <Button type="primary" shape="round" size="large" icon={<PlusOutlined />} onClick={handleMapBoundChange}>
              Camera
            </Button>
          </Link>
        </div>
      </Row>
    </>
  );
};

export default CameraPage;
