import { Button, Col, Divider, Form, Grid, Input, InputNumber, notification, Row, Select, Spin } from 'antd';
import * as Api from 'api';
import CameraAddMap from 'components/camera/cameraAddMap';
import { CameraPinType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
const CameraAddPage = () => {
  // const { cameras, filterOptions, setCameras } = useApi();
  const [form] = Form.useForm();
  const { md } = useBreakpoint();
  const history = useHistory();

  const { auth: { profile } } = useAuth();
  const { cameraMapBound } = useApi();
  const [loading, setLoading] = useState(false);
  const [cameras, setCameras] = useState<CameraPinType[]>([]);
  const [camera, setCamera] = useState<CameraPinType>({
    id: 0,
    name: '',
    IMEI: '',
    latitude: cameraMapBound.center.lat,
    longitude: cameraMapBound.center.lng,
    type: 'Camera',
    fix_location: 0,
  });

  useEffect(() => {
    httpGetCameras();
  }, []);

  const onValuesChange = (values, allvalues) => {
    console.log(allvalues);
    const newCamera = { ...camera, ...allvalues };
    setCamera(newCamera);
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    if (camera.IMEI.length > 0 && !camera.IMEI.match('86[0-9]{13}')) {
      notification.open({
        message: 'The number you have entered is not a valid imei number. The number should start with 86 and it is located on the bottom of the box and above the screen of the camera.',
      });
      return;
    }

    const params = {
      camera_name: camera.name,
      IMEI: camera.IMEI,
      id: 0,
      latitude: camera.latitude,
      longitude: camera.longitude,
      fix_location: 1,
      api_version: 3,
    };
    setLoading(true);
    Api.CAMERA_CREATE(params).then(((res: any) => {
      setLoading(false);
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        const path = '/camera';
        history.push(path);
      } else {
        notification.open({
          message: data.message,
        });
      }
      // setForecastdata(data.data);
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onDragEnd = (evt) => {
    const { latLng } = evt;
    const newCamera = {
      ...camera,
      ...{
        latitude: Number(latLng.lat().toFixed(6)),
        longitude: Number(latLng.lng().toFixed(6)),
      },
    };
    setCamera(newCamera);
    form.setFieldsValue(newCamera);
  };

  const httpGetCameras = () => {
    setLoading(true);
    Api.MAP_CAMERAS_PINS_DETAIL().then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      setCameras(result.cameras);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const changeCamera = (value) => {
    console.log(`selected ${value}`);
    const newCamera = {
      ...camera,
      ...{
        latitude: cameras[value].latitude,
        longitude: cameras[value].longitude,
      },
    };
    setCamera(newCamera);
    form.setFieldsValue(newCamera);
  };

  return (
    <>
      <Row>
        {md && (
          <Col md={12} className={classes.leftMap} style={{ height: '80vh' }}>
            <CameraAddMap camera={camera} onDragEnd={onDragEnd} zoom={cameraMapBound.zoom} cameras={cameras} />
          </Col>
        )}
        <Col md={12} xs={24}>
          <div className={classes.content} style={{ padding: md ? 24 : 16 }}>
            <Spin spinning={loading} size="large">
              <Form
                {...layout}
                form={form}
                name="basic"
                initialValues={camera}
                onValuesChange={onValuesChange}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <p>If you have a WiseEye Smart Cam, please enter the IMEI below.</p>
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input camera name',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label="IMEI"
                  name="IMEI"
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || value.length == 0 || value.match('86[0-9]{13}')) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('The number you have entered is not a valid imei number. The number should start with 86 and it is 15 in length, it is located on the bottom of the box and above the screen of the camera.'));
                      },
                    }),
                  ]}
                >
                  <Input />
                </Form.Item>
                <Divider />
                <p>
                  You can use the search bar at the top of the map to set the location of the camera.<br />
                  You can also drag the pin on the map to the correct location.<br />
                  Lastly, you can also use the Move Near Camera feature to move the new camera to the same location as one of your existing cameras.
                </p>
                <Form.Item
                  label="Latitude"
                  name="latitude"
                  rules={[
                    {
                      required: true,
                      message: 'Latitude',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  label="Longitude"
                  name="longitude"
                  rules={[
                    {
                      required: true,
                      message: 'Longitude',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                {
                  cameras.length > 0 && (
                    <Form.Item
                      label="Move Near Camera"
                    >
                      <Select
                        size="large"
                        className={classes.select}
                        onChange={changeCamera}
                      >
                        {cameras.map((val: any, i) => (
                          <Select.Option key={val.id} value={i}>{val.name}</Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </Spin>
            {md == false && (
              <Col xs={24} md={24} style={{ height: '400px' }}>
                <CameraAddMap camera={camera} onDragEnd={onDragEnd} zoom={cameraMapBound.zoom} cameras={cameras} />
              </Col>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CameraAddPage;
