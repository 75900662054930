import { Button, Card, Col, Divider, Form, Input, Row, Select, Switch, TimePicker, Tooltip, notification } from 'antd';
import * as Api from 'api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { optionsMini2 } from '../options';
import classes from './style.module.scss';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const CameraRemoteMiniBasic = ({ IMEI, setting, onChange }) => {
  const [form] = Form.useForm();
  const [switchName, setSwitchName] = useState(true);
  const [switchDelay, setSwitchDelay] = useState(true);
  const [cameraMode, setCameraMode] = useState<any>(1);
  const [videoQuality, setVideoQuality] = useState<any>(1);
  useEffect(() => {
    if (setting) {
      console.log('setting', setting);

      setCameraMode(setting.camera_mode);
      setVideoQuality(setting.video_quality);

      if (setting.camera_name == '' || setting.camera_name == 'OFF') {
        setSwitchName(false);
      } else {
        setSwitchName(true);
      }

      let { delay } = setting;
      if (delay == 'OFF') {
        setSwitchDelay(false);
        delay = '00:00:00';
      } else {
        setSwitchDelay(true);
      }

      form.setFieldsValue({ ...setting, delayMoment: moment(delay, 'HH:mm:ss') });
    }
  }, [setting]);

  const onGetPicture = () => {
    const params = {
      IMEI,
    };
    Api.REMOTE_CONTROL_GETAPICKTURE_MINI(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onFinish = (values) => {
    const updatedSetting = {};
    for (let [key, value] of Object.entries(values)) {
      if (key == 'delayMoment') {
        key = 'delay';
        if (value && switchDelay) {
          value = values.delayMoment.format('HH:mm:ss');
        } else {
          value = 'OFF';
        }
      } else if (key == 'camera_name') {
        if (!switchName) {
          value = 'OFF';
        }
      }
      updatedSetting[key] = value;
      key = '';
    }

    onChange(updatedSetting);
  };

  const disabledSeconds = (hour: number, minute: number) => {
    if (hour == 0 && minute == 0) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    }
    return [];
  };

  return (
    <>
      <Form
        {...layout}
        form={form}
        name="basic"
        onFinish={onFinish}
      >
        <Card className={classes.card}>
          <Row>
            <Col md={12} xs={24}>
              <Form.Item
                label="Camera Mode"
                name="camera_mode"
                tooltip={{ title: 'Choose whether you wish to receive videos, pictures or both videos and pictures. Please be aware that receiving videos will use significantly more data than just pictures.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMini2.CameraMode}
                  onChange={(value) => {
                    setCameraMode(value);
                    if (value == 2) {
                      form.setFieldsValue({ multi_shot: 1 });
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Burst Mode"
                name="multi_shot"
                tooltip={{ title: 'Choose how many pictures you would like the camera to take with each motion trigger. This only works in Photo mode.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMini2.MultiShot}
                  disabled={cameraMode != 1}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Motion Sensitivity"
                name="pir_sensitivity"
                tooltip={{ title: '9 - more sensitive to motion. 1 - least sensitive to motion.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMini2.PirSensitivity}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Picture Size"
                name="picture_size"
                tooltip={{ title: 'This is the size of the picture that is saved on the SD Card. This will not affect the size of the images transmitted to HuntControl.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMini2.PictureSize}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Camera Check In"
                name="sms_remote"
                tooltip={{ title: 'Once a Day - Best Battery Life, Up to 24hrs to execute remote control commands. Always Available - Instantly Execute commands, significant battery drain. Recommended only for those with solar panels or larger battery adapters.', placement: 'bottom' }}
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMini2.Cellular}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Video Upload"
                name="trans_video"
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMini2.VideoSending}
                  disabled={cameraMode == 1}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Video Quality"
                name="video_quality"
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={optionsMini2.VideoSize}
                  onChange={(value) => { setVideoQuality(value); }}
                  disabled={cameraMode == 1}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Video Length"
                name="video_length"
              >
                <Select
                  className={classes.control}
                  size="large"
                  options={videoQuality == 1 ? optionsMini2.VideoLength1080 : optionsMini2.VideoLength}
                  disabled={cameraMode == 1}
                />
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item
                label="Delay"
                className={classes.divTimeLapse}
                tooltip={{ title: 'This is the amount of time that the camera will wait between taking pictures.', placement: 'bottom' }}
              >
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="delayMoment"
                  >
                    <TimePicker
                      className={classes.control}
                      size="large"
                      disabledSeconds={disabledSeconds}
                      showNow={false}
                      allowClear={false}
                      disabled={!switchDelay}
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch
                      checked={switchDelay}
                      onChange={(checked) => {
                        console.log(`switch to ${checked}`);
                        setSwitchDelay(checked);
                        if (checked) {
                          form.setFieldsValue({ delayMoment: moment('00:00:10', 'HH:mm:ss') });
                        }
                      }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col md={12} xs={24}>
              <Form.Item label="Camera Name" className={classes.divTimeLapse}>
                <Input.Group compact>
                  <Form.Item
                    label=""
                    name="camera_name"
                  >
                    <Input
                      disabled={!switchName}
                      maxLength={12}
                      placeholder="OFF"
                    />
                  </Form.Item>
                  &nbsp;
                  <Form.Item
                    label=""
                  >
                    <Switch checked={switchName} onChange={(value) => { setSwitchName(value); }} />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
          <div className={classes.divFooter}>
            <Button type="primary" htmlType="submit">
              Send
            </Button>
          </div>
          <Divider />
          <div className={classes.divBottom}>
            <div className={classes.divLine}>
              <Tooltip title="This will tell the camera to send you a picture the next time that the camera checks in. If your camera is set to Daily Cellular Mode then it may take up to 24hrs to get the image. If your camera is set to Instant Cellular Mode then it should send the picture in a few minutes at most. The picture will show in your image gallery.">
                <Button type="primary" onClick={onGetPicture}>
                  Get A Picture
                </Button>
              </Tooltip>
            </div>
          </div>
        </Card>
      </Form>
    </>
  );
};

export default CameraRemoteMiniBasic;
