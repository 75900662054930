import { Button, Col, Form, Grid, Input, InputNumber, notification, Row, Select, Spin } from 'antd';
import * as Api from 'api';
import CameraAddMap from 'components/camera/cameraAddMap';
import { CameraPinType } from 'helpers/types';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import useAuth from 'redux/auth/auth.hook';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;
const { Option } = Select;

const pinTypes = [
  { value: 'Stand', name: 'Stand' },
  { value: 'Feeder', name: 'Feeder' },
  { value: 'Camp', name: 'Camp' },
  { value: 'Trail', name: 'Trail' }];
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

interface ParamTypes {
  pin_id: string
}
const CameraEditPin = () => {
  const { pin_id } = useParams<ParamTypes>();

  const [form] = Form.useForm();
  const { md } = useBreakpoint();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { auth: { profile } } = useAuth();
  const [cameras, setCameras] = useState<CameraPinType[]>([]);
  const [camera, setCamera] = useState<CameraPinType>({
    id: 0,
    name: '',
    IMEI: '',
    latitude: 30.684755,
    longitude: -90.969077,
    type: 'Camera',
    fix_location: 0,
  });

  useEffect(() => {
    if (pin_id) {
      loadData();
      httpGetCameras();
    }
  }, [pin_id]);

  const loadData = () => {
    setLoading(true);
    Api.MAP_GET_PIN(pin_id).then(((res: any) => {
      const result = JSON.parse(res.text);
      console.log(result);
      setCamera(result);
      form.setFieldsValue(result);
      setLoading(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onValuesChange = (values, allvalues) => {
    console.log(allvalues);
    const newCamera = { ...camera, ...allvalues };
    setCamera(newCamera);
  };

  const onFinish = (values) => {
    console.log('Success:', values);
    const params = {
      user_id: profile.user_id,
      name: camera.name,
      id: camera.id,
      latitude: camera.latitude,
      longitude: camera.longitude,
      IMEI: camera.IMEI,
      type: camera.type,
      fix_location: 1,
    };
    Api.MAP_PIN_EDIT(params).then(((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'ok') {
        const path = '/camera';
        history.push(path);
      } else {
        notification.open({
          message: data.msg,
        });
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const onDragEnd = (evt) => {
    const { latLng } = evt;
    // updateMarkerPositionTxt(latLng);

    const newCamera = {
      ...camera,
      ...{
        latitude: Number(latLng.lat().toFixed(6)),
        longitude: Number(latLng.lng().toFixed(6)),
      },
    };
    setCamera(newCamera);
    form.setFieldsValue(newCamera);
  };

  const deleteItem = () => {
    if (window.confirm('Are you sure you want to delete this pin?')) {
      Api.MAP_PIN_DELETE(pin_id).then((res) => {
        console.log('delete camera', res);
        history.goBack();
      }).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  };

  const httpGetCameras = () => {
    setLoading(true);
    Api.CAMERA_GET_LIVE_ALL().then(((res: any) => {
      setLoading(false);
      const result = JSON.parse(res.text);
      setCameras(result.data);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <>
      <Row>
        {md && (
          <Col md={12} className={classes.leftMap} style={{ height: '80vh' }}>
            <CameraAddMap camera={camera} onDragEnd={onDragEnd} zoom={14} cameras={cameras} />
          </Col>
        )}
        <Col md={12} xs={24}>
          <div className={classes.content} style={{ padding: md ? 24 : 16 }}>
            <Spin spinning={loading} size="large">
              <Form
                {...layout}
                form={form}
                name="basic"
                onValuesChange={onValuesChange}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  label="Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input camera name',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Latitude"
                  name="latitude"
                  rules={[
                    {
                      required: true,
                      message: 'Latitude',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label="Longitude"
                  name="longitude"
                  rules={[
                    {
                      required: true,
                      message: 'Longitude',
                    },
                  ]}
                >
                  <InputNumber style={{ width: '100%' }} />
                </Form.Item>
                <Form.Item
                  label="Type"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: 'Type',
                    },
                  ]}
                >
                  <Select size="large">
                    {pinTypes.map((val: any) => (
                      <Option key={val.value} value={val.value}>{val.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                {camera.type == 'Feeder' && (
                  <Form.Item
                    label="IMEI"
                    name="IMEI"
                    rules={[
                      {
                        required: false,
                        message: 'IMEI',
                      },
                    ]}
                  >
                    <Input style={{ width: '100%' }} placeholder="Please input IMEI if smart feeder." />
                  </Form.Item>
                )}
                <Form.Item {...tailLayout}>
                  <Row>
                    <Col offset={1} span={6}>
                      <Button type="ghost" onClick={deleteItem}>
                        Delete
                      </Button>
                    </Col>
                    <Col offset={1} span={6}>
                      <Button type="primary" htmlType="submit">
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </Spin>
            {md == false && (
              <Col xs={24} md={24} style={{ height: '400px' }}>
                <CameraAddMap camera={camera} onDragEnd={onDragEnd} zoom={14} cameras={cameras} />
              </Col>
            )}
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CameraEditPin;
