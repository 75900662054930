import { FieldTimeOutlined, FileImageOutlined, MailOutlined, MinusOutlined } from '@ant-design/icons';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Slider, Stack, ThemeProvider, Typography } from '@mui/material';
import { Button, Card, Input, notification, Select, Spin } from 'antd';
import * as Api from 'api';
import ProfileNotificationChecklist from 'components/profile/profileNotificationChecklist';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import outerTheme from 'theme/mui';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ThresholdCard from 'components/profile/ThresholdCard';
import ThresholdCardHeader from 'components/profile/ThresholdCardHeader';
import classes from './style.module.scss';

const ProfileDetectionThreshold = () => {
  const [thresholds, setThresholds] = useState<any[]>([]);

  useEffect(() => {
    Api.DETECTION_THRESHOLD().then(((res: any) => {
      console.log(res.text);
      const data = JSON.parse(res.text);
      setThresholds(data);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  }, []);

  const changeValue = (index, value) => {
    const newData = JSON.parse(JSON.stringify(thresholds));
    newData[index].value = value;
    setThresholds(newData);
  };

  const changeEnable = (index, value) => {
    const newData = JSON.parse(JSON.stringify(thresholds));
    newData[index].enable = value;
    setThresholds(newData);
  };

  const handleDown = (index) => {
    if (index + 1 < thresholds.length) {
      const newData = JSON.parse(JSON.stringify(thresholds));
      newData[index] = thresholds[index + 1];
      newData[index + 1] = thresholds[index];
      setThresholds(newData);
      console.log(newData);
    }
  };

  const handleUp = (index) => {
    if (index > 0) {
      const newData = JSON.parse(JSON.stringify(thresholds));
      newData[index] = thresholds[index - 1];
      newData[index - 1] = thresholds[index];
      setThresholds(newData);
      console.log(newData);
    }
  };

  const onSave = () => {
    console.log('save');

    const params = [];
    let count = 0;
    thresholds.forEach((threshold) => {
      threshold.priority = count;
      count += 1;
    });
    console.log(thresholds);
    Api.DETECTION_THRESHOLD_UPDATE(thresholds).then(((res: any) => {
      notification.open({
        message: res.text,
      });
    }));
  };

  return (
    <Card title="Detection Threshold" className={classes.profileCard} extra={<Button type="primary" onClick={onSave}> Save </Button>}>
      <ThemeProvider theme={outerTheme}>
        <ThresholdCardHeader />
        {thresholds.map((val, i) => (
          <ThresholdCard key={i} value={val} index={i} changeValue={changeValue} changeEnable={changeEnable} handleDown={handleDown} handleUp={handleUp} />
        ))}
      </ThemeProvider>
    </Card>
  );
};

export default ProfileDetectionThreshold;
