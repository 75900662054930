import React, { useEffect, useState } from 'react';
import { CloseOutlined, StarOutlined, FilterOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Drawer, Form, Grid, Input, Modal, Row, Space, Spin, notification } from 'antd';
import * as Api from 'api';
import useApi from 'redux/api/api.hook';
import outerTheme from 'theme/mui';
import { Box, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, OutlinedInput, Radio, RadioGroup, Select, SelectChangeEvent, Theme, ThemeProvider, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const editCategories = [
  'Deer (Antlered)',
  'Deer (Non antlered)',
  'Hog',
  'Turkey',
  'People',
  'Vehicle',
  'Predator',
  'Other',
];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ImageTrashMorePage = () => {
  const { md } = useBreakpoint();
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false);
  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [tagArr, setTagArr] = useState<any[]>([]);
  const [fromDate, setFromDate] = React.useState<Dayjs | null>(null);
  const [toDate, setToDate] = React.useState<Dayjs | null>(null);
  const [dateRangeType, setDateRangeType] = useState('1');

  const [selectedCategories, setSelectedCategories] = React.useState<string[]>(['Other']);
  const [selectedCameras, setSelectedCameras] = useState<any[]>([]);
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [enableAutoTrash, setEnableAutoTrash] = useState(true);

  const theme = useTheme();

  const [state, setState] = React.useState({
    trash_favorite: false,
    trash_tagged: false,
  });
  const { trash_favorite, trash_tagged } = state;

  const handleChangeKeep = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };

  const onEnableAutoTrash = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnableAutoTrash(event.target.checked);
    const params = {
      is_auto_trash: event.target.checked ? 1 : 0,
    };
    Api.AUTO_TRASH_OPTIONS(params).then(((res: any) => {
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const getCameraName = (camera_id: String) => {
    console.log(camera_id);
    let i;
    for (i = 0; i < cameraArr.length; i++) {
      if (cameraArr[i].id == camera_id) {
        return cameraArr[i].name;
      }
    }
    return '';
  };

  const handleChangeCategories = (event: SelectChangeEvent<typeof selectedCategories>) => {
    const {
      target: { value },
    } = event;
    console.log(value);
    const newCategoriese = (typeof value === 'string' ? value.split(',') : value);
    if (newCategoriese.includes('') || newCategoriese.length == editCategories.length) {
      setSelectedCategories([]);
    } else {
      setSelectedCategories(newCategoriese);
    }
  };

  const handleSelectCameras = (event: SelectChangeEvent<typeof selectedCameras>) => {
    const {
      target: { value },
    } = event;

    const newSelectedCameras = (typeof value === 'string' ? value.split(',') : value);
    if (newSelectedCameras.includes('') || newSelectedCameras.length == cameraArr.length) {
      setSelectedCameras([]);
    } else {
      setSelectedCameras(newSelectedCameras);
    }
  };

  const handleSelectTags = (event: SelectChangeEvent<typeof selectedTags>) => {
    const {
      target: { value },
    } = event;
    const newTags = (typeof value === 'string' ? value.split(',') : value);

    if (newTags.includes('') || newTags.length == tagArr.length) {
      setSelectedTags([]);
    } else {
      setSelectedTags(newTags);
    }
  };

  const onChangeDateRangeType = (e) => {
    const index = e.target.value;
    setDateRangeType(index);

    if (index == 1) {
      setFromDate(null);
      setToDate(null);
    } else if (index == 2) {
      setFromDate(dayjs());
      setToDate(dayjs());
    } else if (index == 3) {
      setFromDate(dayjs().subtract(1, 'day'));
      setToDate(dayjs());
    } else if (index == 4) {
      setFromDate(dayjs().subtract(1, 'week'));
      setToDate(dayjs());
    } else if (index == 5) {
      setFromDate(dayjs().subtract(1, 'month'));
      setToDate(dayjs());
    }
  };

  useEffect(() => {
    Api.CAMERAS_GROUPS_TAGS().then(((res: any) => {
      const result = JSON.parse(res.text);
      setCameraArr(result.cameras);
      setTagArr(result.tags.filter((element) => element.tag !== ''));
    })).catch((error: any) => {
      console.log('error ===>', error);
    });

    Api.PROFILE_GET_USER().then(((res: any) => {
      console.log(res.text);
      const data = JSON.parse(res.text);
      setEnableAutoTrash(data.is_auto_trash == 1);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  }, []);

  const onMoveTrash = () => {
    const categories: string[] = [];
    if (selectedCategories.includes('Deer (Antlered)') && selectedCategories.includes('Deer (Non antlered)')) {
      categories.push('Deer');
    } else if (selectedCategories.includes('Deer (Antlered)')) {
      categories.push('Deer (Antlered)');
    } else if (selectedCategories.includes('Deer (Non antlered)')) {
      categories.push('Deer (Non antlered)');
    }

    selectedCategories.forEach((category) => {
      if (category != 'Deer (Antlered)' && category != 'Deer (Non antlered)') { categories.push(category); }
    });

    const params = {
      categories,
      cameras: selectedCameras,
      tags: trash_tagged ? selectedTags : [],
      fromDate: fromDate == null ? '' : fromDate.format('YYYY-MM-DD'),
      toDate: toDate == null ? '' : toDate.format('YYYY-MM-DD'),
      include_favorite: trash_favorite ? 1 : 0,
      include_tagged: trash_tagged ? 1 : 0,
    };

    setLoading(true);
    Api.MOVE_TRASH_WITH_OPTIONS(params).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: res.text,
      });
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const onMoveTrashCount = () => {
    const categories: string[] = [];
    if (selectedCategories.includes('Deer (Antlered)') && selectedCategories.includes('Deer (Non antlered)')) {
      categories.push('Deer');
    } else if (selectedCategories.includes('Deer (Antlered)')) {
      categories.push('Deer (Antlered)');
    } else if (selectedCategories.includes('Deer (Non antlered)')) {
      categories.push('Deer (Non antlered)');
    }

    selectedCategories.forEach((category) => {
      if (category != 'Deer (Antlered)' && category != 'Deer (Non antlered)') { categories.push(category); }
    });

    const params = {
      categories,
      cameras: selectedCameras,
      tags: trash_tagged ? selectedTags : [],
      fromDate: fromDate == null ? '' : fromDate.format('YYYY-MM-DD'),
      toDate: toDate == null ? '' : toDate.format('YYYY-MM-DD'),
      include_favorite: trash_favorite ? 1 : 0,
      include_tagged: trash_tagged ? 1 : 0,
    };

    setLoading(true);
    Api.MOVE_TRASH_WITH_OPTIONS_COUNT(params).then(((res: any) => {
      setLoading(false);

      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" size="small" onClick={() => { notification.close(key); onMoveTrash(); }}>
          Confirm
        </Button>
      );
      notification.open({
        message: res.text,
        btn,
        key,
      });
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  return (
    <>
      <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
        <ThemeProvider theme={outerTheme}>
          <Row>
            <Col md={12} xs={24} style={{ paddingRight: md ? 8 : 0 }}>
              <Card title="Move Images to Trash" className={classes.profileCard}>
                <Spin spinning={loading} size="large">
                  <div className={classes.profileItem}>
                    <p className="text-muted">Please choose which images you would like to delete from the options below.</p>

                    <FormControl sx={{ m: 1, width: '100%' }} size="small">
                      <FormLabel id="demo-controlled-radio-buttons-group">Choose Category to Delete Images from:</FormLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        displayEmpty
                        value={selectedCategories}
                        onChange={handleChangeCategories}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>All Categories</em>;
                          }
                          return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={value} />
                              ))}
                            </Box>
                          );
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          <em>All Categories</em>
                        </MenuItem>
                        {editCategories.map((name) => (
                          <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, selectedCategories, theme)}
                          >
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%' }} size="small">
                      <FormLabel id="demo-controlled-radio-buttons-group">Choose Camera to Delete Images from:</FormLabel>
                      <Select
                        labelId="demo-multiple-chip-label"
                        id="demo-multiple-chip"
                        multiple
                        displayEmpty
                        value={selectedCameras}
                        onChange={handleSelectCameras}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <em>All Cameras</em>;
                          }
                          return (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                              {selected.map((value) => (
                                <Chip key={value} label={getCameraName(value)} />
                              ))}
                            </Box>
                          );
                        }}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="">
                          <em>All Cameras</em>
                        </MenuItem>
                        {cameraArr.map((camera) => (
                          <MenuItem
                            key={camera.id}
                            value={camera.id}
                            style={getStyles(camera.id, selectedCameras, theme)}
                          >
                            {camera.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%' }} size="small">
                      <FormLabel id="demo-controlled-radio-buttons-group">Date range:</FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={dateRangeType}
                        onChange={onChangeDateRangeType}
                      >
                        <FormControlLabel value="1" control={<Radio />} label="All" />
                        <FormControlLabel value="2" control={<Radio />} label="Today" />
                        <FormControlLabel value="3" control={<Radio />} label="Yesterday" />
                        <FormControlLabel value="4" control={<Radio />} label="Last Week" />
                        <FormControlLabel value="5" control={<Radio />} label="Last Month" />
                        <FormControlLabel value="6" control={<Radio />} label="Custom" />
                      </RadioGroup>
                      {/* {dateRangeType == '6' && ( */}
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker', 'DatePicker']}>
                          <DatePicker
                            label="From"
                            value={fromDate}
                            disabled={dateRangeType != '6'}
                            slotProps={{
                              textField: { size: 'small' },
                              actionBar: {
                                actions: ['today', 'clear'],
                              },
                            }}
                            onChange={(newValue) => setFromDate(newValue)}
                          />
                          <DatePicker
                            label="To"
                            value={toDate}
                            disabled={dateRangeType != '6'}
                            slotProps={{
                              textField: { size: 'small' },
                              actionBar: {
                                actions: ['today', 'clear'],
                              },
                            }}
                            onChange={(newValue) => setToDate(newValue)}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                      {/* )} */}
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '100%' }} size="small">
                      <strong style={{ marginTop: 16 }}><QuestionCircleOutlined />&nbsp;Move These Images Too</strong>
                      <p className="text-muted" style={{ marginBottom: 0 }}>By default, images that are marked as favorites or have tags are not deleted. Please check below if you would like to delete these images as well.</p>
                      {/* <InputLabel id="demo-multiple-chip-label">Move These Images Too</InputLabel> */}
                      <FormGroup
                        row
                        style={{ marginLeft: '16px' }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox checked={trash_favorite} onChange={handleChangeKeep} name="trash_favorite" />
                          }
                          label="Favorite"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox checked={trash_tagged} onChange={handleChangeKeep} name="trash_tagged" />
                          }
                          label="Tagged"
                        />
                      </FormGroup>
                    </FormControl>
                    {
                      trash_tagged && (
                        <FormControl sx={{ m: 1, width: '100%' }} size="small">
                          <Select
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            multiple
                            displayEmpty
                            value={selectedTags}
                            onChange={handleSelectTags}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <em>All Tags</em>;
                              }
                              return (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                  {selected.map((value) => (
                                    <Chip key={value} label={value} />
                                  ))}
                                </Box>
                              );
                            }}
                            MenuProps={MenuProps}
                          >
                            <MenuItem value="">
                              <em>All Tags</em>
                            </MenuItem>
                            {tagArr.map((tag) => (
                              <MenuItem
                                key={tag.tag}
                                value={tag.tag}
                                style={getStyles(tag.tag, selectedTags, theme)}
                              >
                                {tag.tag}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )
                    }
                    <div className={classes.divButton}>
                      <Button type="primary" onClick={onMoveTrashCount}>Move</Button>
                    </div>
                  </div>
                </Spin>
              </Card>
            </Col>
            <Col md={12} xs={24} style={{ paddingRight: md ? 8 : 0 }}>
              <Card title="Auto Trash Options" className={classes.profileCard}>
                <FormControl sx={{ m: 1, width: '100%', alignItems: 'center' }} size="small">
                  <p className="text-muted">To help keep your account more organized, and allow HuntControl to operate quicker, we can automatically move pictures, from the Other category, that are not tagged or marked as favorites to the trash can on a monthly basis. Please choose below if you would like to enable this feature.</p>
                  <FormControlLabel
                    control={
                      <Checkbox checked={enableAutoTrash} onChange={onEnableAutoTrash} name="enableAutoTrash" />
                    }
                    label="Enable"
                  />
                </FormControl>
              </Card>
            </Col>
          </Row>
        </ThemeProvider>
      </div>
    </>
  );
};

export default ImageTrashMorePage;
