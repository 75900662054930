import { FieldTimeOutlined, FileImageOutlined, MailOutlined, MinusOutlined } from '@ant-design/icons';
import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, IconButton, List, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Slider, Stack, ThemeProvider, Typography } from '@mui/material';
import { Button, Input, notification, Select, Spin } from 'antd';
import * as Api from 'api';
import ProfileNotificationChecklist from 'components/profile/profileNotificationChecklist';
import React, { useEffect, useState } from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import classes from './style.module.scss';

const ThresholdCard = (props) => {
  const disableUp = (props.index == 0);
  const disableDown = (props.index == 6);

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    // console.log(newValue);
    props.changeValue(props.index, newValue);
  };

  const handleChangeEnable = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.changeEnable(props.index, event.target.checked ? 1 : 0);
  };

  const handleDown = () => {
    props.handleDown(props.index);
  };

  const handleUp = () => {
    props.handleUp(props.index);
  };

  return (
    <div className={classes.divParent}>
      <IconButton disabled={disableUp} onClick={handleUp}>
        <ArrowDropUpIcon />
      </IconButton>
      <IconButton disabled={disableDown} onClick={handleDown}>
        <ArrowDropDownIcon />
      </IconButton>
      <h3 className={classes.textCategory}>
        {props.value.category}
      </h3>
      <Slider
        aria-label="Temperature"
        value={props.value.value}
        valueLabelDisplay="auto"
        step={1}
        marks
        min={1}
        max={5}
        onChange={handleSliderChange}
      />
      <div className={classes.divEnable}>
        <Checkbox checked={props.value.enable == 1} onChange={handleChangeEnable} />
      </div>
    </div>
  );
};

export default ThresholdCard;
