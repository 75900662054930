import React, { useEffect, useState } from 'react';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Col, Collapse, Divider, Row, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import AttComponent from 'components/camera/cameraSimbols/AttComponent';
import BatteryComponent from 'components/camera/cameraSimbols/BatteryComponent';
import CameraVersion from 'components/camera/cameraSimbols/CameraVersion';
import SignalComponent from 'components/camera/cameraSimbols/SignalComponent';
import moment from 'moment';
import classes from './style.module.scss';

const CameraRemoteMiniBigReport = ({ camera, device, deviceSetting }) => {
  const { md } = useBreakpoint();
  return (
    <Collapse defaultActiveKey={[]}>
      <Collapse.Panel header="Settings Received From Camera" key="1">
        <div className={classes.divBottomHeader}>
          <div className={classes.divTitle}>
            <h1 className={classes.labelTitle}>{camera.name}</h1>
            <Tooltip placement="leftTop" title="Below you can see the most recent settings on your camera. These may be different from the settings above. The cameras check in with HuntControl based on your settings above.Cameras in Always Available will get your commands quickly and update quickly, but this requires much power and will drain AA batteries. Cameras in Daily will get your commands only once a day, so there may be a delay between the commands being sent and the settings being changed on the camera. If your camera is in Daily, please allow up to 24 hrs for the commands to process.The Last Check In time shows the last time the camera checked for new commands from HuntControl.">
              <QuestionCircleOutlined style={{ color: '#DC6300' }} />
            </Tooltip>
          </div>
          <div className={classes.divFirmware}>
            <Row className={classes.labelFirmware}>
              <Col md={12} xs={24}>
                <span>Last Updated: {device.last_text_uploaded_at > 1000 ? moment(device.last_text_uploaded_at * 1000).format('hh:mm A - MMM D, Y') : ''}</span>
              </Col>
              <Col md={12} xs={24}>
                <span>Firmware Version: {device.firmware_version}</span>
              </Col>
            </Row>
          </div>
        </div>
        <Divider />
        <div className={classes.divBottomContent}>
          <div className={classes.divCameraType}>
            <CameraVersion detail={device} size={md ? 150 : 60} />
            <AttComponent detail={device} />
          </div>
          {md && (
            <div className={classes.divText}>
              <h3>Settings Received From Camera:</h3>
              <table className={classes.border_none}>
                <tr>
                  <td className={classes.td_title}>Camera Mode:</td>
                  <td className={classes.td_value}>{deviceSetting.camera_mode}</td>
                  <td className={classes.td_title}>Delay: </td>
                  <td className={classes.td_value}>{deviceSetting.delay}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Burst Mode:</td>
                  <td className={classes.td_value}>{deviceSetting.burst_mode}</td>
                  <td className={classes.td_title}>Time Lapse: </td>
                  <td className={classes.td_value}>{deviceSetting.time_lapse}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Picture Size:</td>
                  <td className={classes.td_value}>{deviceSetting.picture_size}</td>
                  <td className={classes.td_title}>Motion Sensitivity: </td>
                  <td className={classes.td_value}>{deviceSetting.motion_sensitivity}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Night Mode: </td>
                  <td className={classes.td_value}>{deviceSetting.night_mode}</td>
                  <td className={classes.td_title}>Work Timer1: </td>
                  <td className={classes.td_value}>{deviceSetting.work_timer1}</td>
                </tr>
                <tr>
                  <td className={classes.td_title}>Camera Check In: </td>
                  <td className={classes.td_value}>{deviceSetting.camera_check_int}</td>
                  <td className={classes.td_title}>Work Timer2: </td>
                  <td className={classes.td_value}>{deviceSetting.work_timer2}</td>
                </tr>
              </table>
            </div>
          )}
          <div className={classes.divImages}>
            <div className={classes.divCard}>
              <img className={classes.image} alt="test" src="/icons_camera/sdcard_icon.png" />
              <p>{(device.sd_card_max - device.sd_card)}GB/{device.sd_card_max}GB</p>
              <p>{Math.round(((device.sd_card_max - device.sd_card) * 100) / (device.sd_card_max + 0.01))}%</p>
            </div>
            <BatteryComponent detail={device} fontSize="large" />
            <div className={classes.iconSignal}>
              <SignalComponent detail={device} fontSize="large" />
            </div>
          </div>
        </div>
        {md == false && (
          <div className={classes.divText}>
            <table className={classes.border_none}>
              <tr>
                <td className={classes.td_title}>Camera Mode:</td>
                <td className={classes.td_value}>{deviceSetting.camera_mode}</td>
                <td className={classes.td_title}>Delay: </td>
                <td className={classes.td_value}>{deviceSetting.delay}</td>
              </tr>
              <tr>
                <td className={classes.td_title}>Burst Mode:</td>
                <td className={classes.td_value}>{deviceSetting.burst_mode}</td>
                <td className={classes.td_title}>Time Lapse: </td>
                <td className={classes.td_value}>{deviceSetting.time_lapse}</td>
              </tr>
              <tr>
                <td className={classes.td_title}>Picture Size:</td>
                <td className={classes.td_value}>{deviceSetting.picture_size}</td>
                <td className={classes.td_title}>Motion Sensitivity: </td>
                <td className={classes.td_value}>{deviceSetting.motion_sensitivity}</td>
              </tr>
              <tr>
                <td className={classes.td_title}>Night Mode: </td>
                <td className={classes.td_value}>{deviceSetting.night_mode}</td>
                <td className={classes.td_title}>Work Timer1: </td>
                <td className={classes.td_value}>{deviceSetting.work_timer1}</td>
              </tr>
              <tr>
                <td className={classes.td_title}>Camera Check In: </td>
                <td className={classes.td_value}>{deviceSetting.camera_check_int}</td>
                <td className={classes.td_title}>Work Timer2: </td>
                <td className={classes.td_value}>{deviceSetting.work_timer2}</td>
              </tr>
            </table>
          </div>
        )}
      </Collapse.Panel>
    </Collapse>
  );
};

export default CameraRemoteMiniBigReport;
