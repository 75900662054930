import { Button, Card, Col, Collapse, Divider, Dropdown, Form, Input, Menu, Modal, Popconfirm, Row, Select, Spin, Switch, TimePicker, Tooltip, notification } from 'antd';
import * as Api from 'api';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { optionsMini2 } from '../options';
import classes from './style.module.scss';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const CameraRemoteMiniAdvanced = ({ IMEI, setting, onChange, isLastFirmware, deviceSetting }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [switchTimeLapse, setSwitchTimeLapse] = useState(true);
  const [workTimer1, setWorkTimer1] = useState(true);
  const [workTimer2, setWorkTimer2] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (setting) {
      console.log('setting', setting);

      let lapse = setting.time_lapse;
      if (lapse == 'OFF') {
        setSwitchTimeLapse(false);
        lapse = '00:00:00';
      } else {
        setSwitchTimeLapse(true);
      }

      let work_timer1 = [moment('00:00', format), moment('00:00', format)];
      if (setting.work_timer1 == 'OFF') {
        setWorkTimer1(false);
      } else {
        setWorkTimer1(true);
        const work_time = setting.work_timer1.split('-');
        work_timer1 = [moment(work_time[0], format), moment(work_time[1], format)];
      }

      let work_timer2 = [moment('00:00', format), moment('00:00', format)];
      if (setting.work_timer2 == 'OFF') {
        setWorkTimer2(false);
      } else {
        setWorkTimer2(true);
        const work_time = setting.work_timer2.split('-');
        work_timer2 = [moment(work_time[0], format), moment(work_time[1], format)];
      }
      form.setFieldsValue({ ...setting, timeLapseMoment: moment(lapse, 'HH:mm:ss'), workTimerMoment1: work_timer1, workTimerMoment2: work_timer2 });
    }
  }, [setting]);

  const onReset = () => {
    const params = {
      IMEI,
    };
    Api.REMOTE_CONTROL_RESET_MINI(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onFirmwareUpdate = () => {
    if (deviceSetting.picture_size == '') {
      notification.open({
        duration: 10,
        message: 'Your Camera has not sent a daily report to HuntControl yet. This usually happens after 24-48hrs of continuous operation. Please wait until the camera checks in, and you can update at that time. Please contact support at support@wiseeyetech.com with any questions.',
      });
    } else if (deviceSetting.camera_check_int == '4 Times Daily (Default)') {
      setIsModalOpen(true);
    } else {
      notification.open({
        duration: 10,
        message: 'Your camera will have to be in Daily or 4 Times Daily Check In Mode for the update to happen. Please change your check in mode using the remote control menu above. You can attempt the firmware update again, after the camera confirms this setting at its next report.',
      });
    }
  };

  const onFinish = (values) => {
    const updatedSetting = {};
    for (let [key, value] of Object.entries(values)) {
      if (key == 'timeLapseMoment') {
        key = 'time_lapse';
        if (switchTimeLapse) {
          value = values.timeLapseMoment.format('HH:mm:ss');
        } else {
          value = 'OFF';
        }
      } else if (key == 'workTimerMoment1') {
        key = 'work_timer1';
        if (workTimer1) {
          const time1 = values.workTimerMoment1[0].format(format);
          const time2 = values.workTimerMoment1[1].format(format);
          value = `${time1}-${time2}`;
        } else {
          value = 'OFF';
        }
      } else if (key == 'workTimerMoment2') {
        key = 'work_timer2';
        if (workTimer2) {
          const time1 = values.workTimerMoment2[0].format(format);
          const time2 = values.workTimerMoment2[1].format(format);
          value = `${time1}-${time2}`;
        } else {
          value = 'OFF';
        }
      }
      updatedSetting[key] = value;
      key = '';
    }
    onChange(updatedSetting);
  };

  const http_formware_update = () => {
    setIsModalOpen(false);
    const params = {
      IMEI,
    };
    Api.REMOTE_CONTROL_FIRMWARE_UPDATE_MINI(params).then((res: any) => {
      const data = JSON.parse(res.text);
      if (data.result == 'OK') {
        const date = new Date(data.estimated_time * 1000).toLocaleString();
        const confirm_message = `Your update request has been sent to the camera and should be completed by ${date}. Please check back at that time.`;
        notification.open({
          message: confirm_message,
        });
      } else {
        notification.open({
          message: data.message,
          duration: 10,
        });
      }
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <Spin spinning={loading} size="large">
      <Collapse defaultActiveKey={[]}>
        <Collapse.Panel header="Advanced Settings" key="1">
          <Form
            {...layout}
            form={form}
            name="basic"
            onFinish={onFinish}
          >
            <Row>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Sending Mode"
                  name="sending_mode"
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsMini2.SendingMode}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Night Mode"
                  name="night_mode"
                  tooltip={{ title: 'This controls the shutter speed at night. Max Range will illuminate things further away, but it will tend to create more blur. Minimum Blur will reduce the amount of blurriness in the photo, but it will reduce the range of the flash at night. Balanced is good for most situations.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsMini2.NightMode}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="IR Flash"
                  name="ir_flash"
                  tooltip={{ title: 'Choose how much flash you wish to use for night time pictures. Low power is better for close subjects. High power tends to wash out close subjects. Low power will reduce visible range in the pictures.', placement: 'bottom' }}
                >
                  <Select
                    className={classes.control}
                    size="large"
                    options={optionsMini2.IRFlash}
                  />
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Time Lapse:"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Allows you to take pictures at an interval, regardless of the motion sensor. You can set the interval as low as 5 seconds or as high as 23 hrs and 59 minutes. Turning on Time Lapse will disable your motion sensor. If you want the camera to be triggered by motion, make sure the Time Lapse is not enabled.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="timeLapseMoment"
                    >
                      <TimePicker
                        className={classes.control}
                        size="large"
                        defaultOpenValue={moment('00:00:00', 'HH:mm:ss')}
                        disabled={!switchTimeLapse}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={switchTimeLapse}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setSwitchTimeLapse(checked);
                          if (checked) {
                            form.setFieldsValue({ timeLapseMoment: moment('00:00:03', 'HH:mm:ss') });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Work Timer1"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="workTimerMoment1"
                    >
                      <TimePicker.RangePicker
                        className={classes.control}
                        size="large"
                        defaultOpenValue={moment('00:00', format)}
                        disabled={!workTimer1}
                        format={format}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={workTimer1}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setWorkTimer1(checked);
                          if (checked) {
                            form.setFieldsValue({ workTimerMoment1: moment('00:00:03', 'HH:mm:ss') });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
              <Col md={12} xs={24}>
                <Form.Item
                  label="Work Timer2"
                  className={classes.divTimeLapse}
                  tooltip={{ title: 'Select which hours during the day that you would like the camera to be active. Do not set this if you wish the camera to always be active.', placement: 'bottom' }}
                >
                  <Input.Group compact>
                    <Form.Item
                      label=""
                      name="workTimerMoment2"
                    >
                      <TimePicker.RangePicker
                        className={classes.control}
                        size="large"
                        defaultOpenValue={moment('00:00', format)}
                        disabled={!workTimer2}
                        format={format}
                      />
                    </Form.Item>
                    &nbsp;
                    <Form.Item
                      label=""
                    >
                      <Switch
                        checked={workTimer2}
                        onChange={(checked) => {
                          console.log(`switch to ${checked}`);
                          setWorkTimer2(checked);
                          if (checked) {
                            form.setFieldsValue({ workTimer2Moment: moment('00:00:03', 'HH:mm:ss') });
                          }
                        }}
                      />
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
              </Col>
            </Row>
            {(deviceSetting.estimated_update_at == null || deviceSetting.estimated_update_at * 1000 < Date.now()) && (
              <>
                <div className={classes.divFooter}>
                  <Button type="primary" htmlType="submit">
                    Send
                  </Button>
                </div>
                <Divider />
                <div className={classes.divBottom}>
                  {
                    (!isLastFirmware) && (
                      <div className={classes.divLine}>
                        <Button type="primary" onClick={onFirmwareUpdate}>
                          Camera Firmware Update
                        </Button>
                      </div>
                    )
                  }
                  <div className={classes.divLine}>
                    <Tooltip placement="bottom" title="The reset command can resolve many common issues with the camera. Only use if you are having trouble with your camera. This will remove all of your settings from the camera. Do you wish to proceed?">
                      <Popconfirm title="Sure to Reset?" onConfirm={onReset}>
                        <Button type="primary">
                          Reset
                        </Button>
                      </Popconfirm>
                    </Tooltip>
                  </div>
                </div>
              </>
            )}
          </Form>
          <Modal title="" visible={isModalOpen} onOk={http_formware_update} onCancel={() => { setIsModalOpen(false); }}>
            <p>The update may take up to 48hrs to complete. During this time you will be unable to send additional remote control commands. The camera will have to have sufficient battery power and signal for the update to complete properly. After the update, your camera will revert to the default values for all settings. Your camera will continue to send images during the update process. None of your images or data will be affected during the update. Do you wish to proceed?</p>
          </Modal>
        </Collapse.Panel>
      </Collapse>
    </Spin>
  );
};

export default CameraRemoteMiniAdvanced;
