import { Card, Col, Row } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import ProfileAccount from 'components/profile/profileAccount';
import ProfileNotification from 'components/profile/profileNotification';
import ProfileSubscription from 'components/profile/profileSubscription';
import ProfileDetectionThreshold from 'components/profile/profileDetectionThreshold';
import React from 'react';
import classes from './style.module.scss';

const DetectionThreshold = () => {
  const { md } = useBreakpoint();
  return (
    <>
      <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
        <Row>
          <Col md={12} xs={24} style={{ paddingRight: md ? 8 : 0 }}>
            <ProfileDetectionThreshold />
          </Col>
          <Col md={12} xs={24} style={{ paddingLeft: md ? 8 : 0 }}>
            <Card title="How to customize your detection threshold:" className={classes.profileCard}>
              ...
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DetectionThreshold;
