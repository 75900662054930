import { Button, Checkbox, Col, DatePicker, Divider, Form, Modal, notification, Popconfirm, Radio, Row, Select, Spin, Tooltip } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import ImageEditTag from 'components/image/ImageEditTag';
import ImageFilterTag from 'components/image/ImageFilterTag';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import { FormControlLabel, FormGroup, ThemeProvider } from '@mui/material';
import { PlayCircleOutlined, SendOutlined, StarOutlined } from '@ant-design/icons';
import outerTheme from 'theme/mui';
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faTrashRestore } from '@fortawesome/free-solid-svg-icons';
import classes from './style.module.scss';

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const FilterTrash = (props) => {
  const [trachCounter, setTrashCounter] = useState(0);
  const [form] = Form.useForm();
  const [formEdit] = Form.useForm();
  const { auth: { profile } } = useAuth();
  const [loading, setLoading] = useState(false);
  const { md } = useBreakpoint();
  const [cameraArr, setCameraArr] = useState<any[]>([]);
  const [cameraGroups, setCameraGroups] = useState<any[]>([]);
  const [tagArr, setTagArr] = useState<any[]>([]);
  const [filterOptions, setFilterOption] = useState({
    category: 'All',
    camera_id: '0',
    fromDate: '',
    toDate: '',
    tag: 'All',
    xl_only: false,
    video_only: false,
    favorite_only: false,
    antlered: 0,
    sort: 'desc',
  });

  useEffect(() => {
    console.log('FilterForecast-->loading');
    Api.CAMERAS_GROUPS_TAGS().then(((res: any) => {
      const data = JSON.parse(res.text);
      setCameraArr(data.cameras);
      setCameraGroups(data.cameraGroups);
      setTagArr(data.tags);
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      if (trachCounter > 0) {
        setTrashCounter(trachCounter - 1);
      }
    }, 1000);
    return () => clearInterval(timer);
  }, [trachCounter]);

  useEffect(() => {
    const newFilter = { ...filterOptions, category: props.category };
    console.log(newFilter);
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  }, [props.category]);

  const onValuesChange = (values, allvalues) => {
    const newFilter = { ...filterOptions, ...allvalues };
    console.log(newFilter);
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onSelectTag = (value) => {
    const newFilter = { ...filterOptions, tag: value };
    console.log(newFilter);
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onChangeDate = (date: any, dateString: string[]) => {
    const newFilter = { ...filterOptions, fromDate: dateString[0], toDate: dateString[1] };
    console.log(newFilter);
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onSelectAll = (e) => {
    props.onSelectAll(e);
  };

  const changeDeerSubCategroy = (e) => {
    const val = e.target.value;
    const newFilter = { ...filterOptions, antlered: val };
    console.log(newFilter);
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onChangeXlOnly = (e) => {
    const newFilter = { ...filterOptions, xl_only: !filterOptions.xl_only, video_only: false };
    console.log(newFilter);
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onChangeVideoOnly = (e) => {
    const newFilter = { ...filterOptions, video_only: !filterOptions.video_only, xl_only: false };
    console.log(newFilter);
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onChangeFavoriteOnly = (e) => {
    const newFilter = { ...filterOptions, favorite_only: !filterOptions.favorite_only };
    console.log(newFilter);
    setFilterOption(newFilter);
    props.handleFilters(newFilter);
  };

  const onRestore = () => {
    props.onRestore();
  };

  const onClear = () => {
    props.onClear();
  };

  return (
    <div className={classes.wrapper}>
      <ThemeProvider theme={outerTheme}>
        <Form form={form} style={{ marginTop: md ? 20 : 0 }} onValuesChange={onValuesChange}>
          <Form.Item label="Sort" name="sort" style={{ marginBottom: 8 }} className={classes.formInputWrapper} initialValue="DESC">
            <Select dropdownStyle={{ position: 'fixed' }}>
              <Option key="DESC" value="DESC">Newest to Oldest</Option>
              <Option key="ASC" value="ASC">Oldest to Newest</Option>
            </Select>
          </Form.Item>
          <Form.Item label="Select All" style={{ marginBottom: 8 }} className={classes.formInputWrapper}>
            <Checkbox className={classes.checkBox} onChange={onSelectAll} />
          </Form.Item>
          <Divider style={{ marginBottom: 8 }}>Filter</Divider>
          <Button type={filterOptions.xl_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeXlOnly} icon={<img src="/icons/size-xl.png" alt="" className={classes.xl_img} />}>
            XL Images Only
          </Button>
          <Button type={filterOptions.video_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeVideoOnly} icon={<PlayCircleOutlined />}>
            Videos Only
          </Button>
          <Button type={filterOptions.favorite_only ? 'primary' : 'default'} className={classes.editTrash} onClick={onChangeFavoriteOnly} icon={<StarOutlined />}>
            Favorites Only
          </Button>
          {(filterOptions.category == 'deer' || filterOptions.category == 'Deer') && (
            <Radio.Group value={filterOptions.antlered} className={classes.categoryGroup} onChange={changeDeerSubCategroy}>
              <Row>
                <Col span={12}><Radio.Button className={classes.categoryBtn} value="0">All Deer</Radio.Button></Col>
                <Col span={12}><Radio.Button className={classes.categoryBtn} value="1">Bucks Only</Radio.Button></Col>
              </Row>
            </Radio.Group>
          )}
          <Form.Item name="camera_id" className={classes.formInputWrapper} initialValue="0">
            <Select dropdownStyle={{ position: 'fixed' }}>
              <Option key={0} value="0">All Cameras</Option>
              <OptGroup label="Cameras">
                {cameraArr.map((val: any) => (
                  <Option key={val.id} value={val.id}>{val.name}</Option>
                ))}
              </OptGroup>
              <OptGroup label="Camera Groups">
                {cameraGroups.map((val: any) => (
                  <Option key={val.id} value={`group${val.id}`}>{val.name}</Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item name="tag" className={classes.formInputWrapper} initialValue="">
            <Select dropdownStyle={{ position: 'fixed' }}>
              <Option key={0} value="">All Tags</Option> :
              {tagArr.map((val: any) => (
                val.tag != '' && (<Option key={val.tag} value={val.tag}>{val.tag}</Option>)
              ))}
            </Select>
          </Form.Item>
          <Form.Item className={classes.formInputWrapper}>
            <div>
              <RangePicker style={{ width: '100%' }} onChange={onChangeDate} />
            </div>
          </Form.Item>
        </Form>
        <Divider style={{ marginBottom: 8 }}>Edit</Divider>
        <Form form={formEdit}>
          <Button type="primary" onClick={onRestore} className={classes.editTrash}>
            Restore
          </Button>
          <Button type="primary" onClick={onClear} className={classes.editTrash}>
            Clear
          </Button>
        </Form>
      </ThemeProvider>
    </div>
  );
};

export default FilterTrash;
