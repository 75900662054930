import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Card, Col, Drawer, Grid, Row, Spin } from 'antd';
import * as Api from 'api';
import ChartBesttime from 'components/activity/chartBesttime';
import ChartBesttimeLine from 'components/activity/chartBesttimeLine';
import ChartCamera from 'components/activity/chartCamera';
import ChartSummaryBar from 'components/activity/chartSummaryBar';
import ChartWindDirection from 'components/activity/chartWindDirection';
import FilterActivity from 'components/activity/filterActivity';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import * as Res from 'res';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const LABELS_TEMP = ['0F', '10F', '20F', '30F', '40F', '50F', '60F', '70F', '80F', '90F', '100F+'];
const LABELS_WIND_SPEED = ['0MPH', '3MPH', '6MPH', '9MPH', '12MPH', '15MPH', '18MPH', '21MPH+'];
const LABELS_WIND_DIR = ['N', 'NNE', 'NE', 'ENE', 'E', 'ESE', 'SE', 'SSE', 'S', 'SSW', 'SW', 'WSW', 'W', 'WNW', 'NW', 'NNW'];
const LABELS_PRESSURE = ['1010MB', '1015MB', '1020MB', '1025MB', '1030MB', '1035MB', '1040MB', '1045MB', '1050MB', '1055MB', '1060MB'];
const LABELS_MOON = ['New Moon', 'Wax. Crescent', '1st Quarter', 'Wax. Gibbous', 'Full Moon', 'Wan. Gibbous', '2nd Quarter', 'Wan. Crescent'];

const ActivityPage = () => {
  const { showLeftFilter, setShowLeftFilter } = useApi();
  const { md, xs } = useBreakpoint();
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingWeather, setLoadingWeather] = useState(false);
  const [loadingDate, setLoadingDate] = useState(false);

  const { auth: { profile } } = useAuth();

  const [weatherMoon, setWeatherMoon] = useState<any[]>([]);
  const [weatherPressure, setWeatherPressure] = useState<any[]>([]);
  const [weatherTemp, setWeatherTemp] = useState<any[]>([]);
  const [weatherWindDir, setWeatherWindDir] = useState<any[]>([]);
  const [weatherWindSpeed, setWeatherWindSpeed] = useState<any[]>([]);

  const [dateDailyKey, setDateDailyKey] = useState<any[]>([]);
  const [dateDailyValue, setDateDailyValue] = useState<any[]>([]);

  const [dateMonthlyKey, setDateMonthlyKey] = useState<any[]>([]);
  const [dateMonthlyValue, setDateMonthlyValue] = useState<any[]>([]);

  const [cameras, setCameras] = useState<any[]>([]);
  const [dataNumber, setDataNumber] = useState<any[]>([]);
  const [dataCamera, setDataCamera] = useState<any>({
    Deer: 0,
    Hog: 0,
    Turkey: 0,
    People: 0,
    Vehicle: 0,
    Predator: 0,
    Other: 0,
  });
  const [filters, setFilters] = useState<any>({
    count_type: 1,
    camera_id: 0,
    endDate: '',
    startDate: '',
  });

  useEffect(() => {
    if (filters) {
      setLoadingSummary(true);
      const camerasFilter = filters.camera_id == 0 ? [] : [filters.camera_id];
      const tagFilter: any[] = [];
      if (filters.tag != '') {
        tagFilter.push(filters.tag);
      }

      const categoryFilter: any[] = [];
      if (filters.category != 'all') {
        categoryFilter.push(filters.category);
      }

      const param = {
        is_minute: filters.count_type,
        cameras: camerasFilter,
        tags: tagFilter,
        categories: categoryFilter,
        fromDate: filters.startDate,
        toDate: filters.endDate,
      };

      Api.CHART_SUMMARY(param).then(((res: any) => {
        const result = JSON.parse(res.text);
        setLoadingSummary(false);
        setCameras(result.cameraNames);
        setDataCamera({
          Deer: result.total.Deer,
          Hog: result.total.Hog,
          Turkey: result.total.Turkey,
          People: result.total.People,
          Vehicle: result.total.Vehicle,
          Predator: result.total.Predator,
          Other: result.total.Other,
        });

        const data: any = [];
        Object.keys(result.number).forEach((key) => {
          const name = result.cameraNames[key] || '';
          if (name == '') { return; }
          let count = 0;
          for (const [category, value] of Object.entries(result.number[key])) {
            if (typeof value === 'number') {
              count += value;
            }
          }
          if (filters.category == 'deer') {
            count = result.number[key].Deer || 0;
          }
          if (filters.category == 'hog') {
            count = result.number[key].Hog || 0;
          }
          if (filters.category == 'turkey') {
            count = result.number[key].Turkey || 0;
          }
          if (filters.category == 'people') {
            count = result.number[key].People || 0;
          }
          if (filters.category == 'vehicle') {
            count = result.number[key].Vehicle || 0;
          }
          if (filters.category == 'predator') {
            count = result.number[key].Predator || 0;
          }
          if (filters.category == 'other') {
            count = result.number[key].Other || 0;
          }
          data.push({ name, count });
        });
        console.log(data);
        setDataNumber(data.sort((a, b) => b.count - a.count));
      })).catch((error: any) => {
        console.log('error ===>', error);
        setLoadingSummary(false);
      });

      setLoadingWeather(true);
      Api.CHART_WEATHER(param).then(((res: any) => {
        const result = JSON.parse(res.text);
        setWeatherMoon(result.moon);
        setWeatherPressure(result.pressure);
        setWeatherTemp(result.temp);
        setWeatherWindDir(result.wind_dir);
        setWeatherWindSpeed(result.wind_speed);
        setLoadingWeather(false);
      })).catch((error: any) => {
        console.log('error ===>', error);
        setLoadingWeather(false);
      });

      setLoadingDate(true);
      Api.CHART_DATE(param).then(((res: any) => {
        const result = JSON.parse(res.text);
        setDateDailyKey(result.daily.key);
        setDateDailyValue(result.daily.value);
        setDateMonthlyKey(result.monthly.key);
        setDateMonthlyValue(result.monthly.value);
        setLoadingDate(false);
      })).catch((error: any) => {
        console.log('error ===>', error);
        setLoadingDate(false);
      });
    }
  }, [filters]);

  const handleFilters = (arg) => {
    setFilters(arg);
  };

  const handleRightFilter = (willShow) => {
    setShowLeftFilter(willShow);
  };

  const capitalizeFirstLetter = (string) => {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
    return '';
  };
  return (
    <>
      <Row>
        {md && (
          <Col md={8} lg={6} xxl={4}>
            <div className={classes.imageFilter}>
              <FilterActivity
                handleFilters={(arg) => handleFilters(arg)}
              />
            </div>
          </Col>
        )}
        {md == false && (
          <Drawer
            title="Filter"
            width={300}
            onClose={() => handleRightFilter(false)}
            visible={showLeftFilter}
            zIndex={900}
            handler={
              (
                <div className={classes.drawerHandle}>
                  {
                    showLeftFilter ? <CloseOutlined onClick={() => handleRightFilter(false)} /> : <FilterOutlined onClick={() => handleRightFilter(true)} />
                  }
                </div>
              )
            }
          >
            <FilterActivity
              handleFilters={(arg) => handleFilters(arg)}
            />
          </Drawer>
        )}
        <Col md={16} xs={24} lg={18} xxl={20}>
          <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
            <Row>
              <Col md={12} xs={24} style={{ paddingRight: md ? 8 : 0 }}>
                <Card title="Summary" className={classes.activityCard} headStyle={{ backgroundColor: '' }}>
                  <Spin spinning={loadingSummary} size="large">
                    <Row>
                      <Col xs={24} md={24}>
                        <ChartCamera data={dataCamera} />
                      </Col>
                    </Row>
                  </Spin>
                </Card>
                <Card title="Camera Activity" className={classes.activityCard} headStyle={{ backgroundColor: '' }}>
                  <Spin spinning={loadingSummary} size="large">
                    <Row>
                      <Col xs={24} md={24}>
                        <ChartSummaryBar data={dataNumber} cameras={cameras} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} />
                      </Col>
                    </Row>
                  </Spin>
                </Card>
                <Card title="Monthly Activity" className={classes.activityCard}>
                  <Spin spinning={loadingDate} size="large">
                    <Row>
                      <Col xs={24}>
                        <ChartBesttimeLine data={dateDailyValue} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Daily" labels={dateDailyKey} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttimeLine data={dateMonthlyValue} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Monthly" labels={dateMonthlyKey} />
                      </Col>
                    </Row>
                  </Spin>
                </Card>
              </Col>
              <Col md={12} xs={24} style={{ paddingLeft: md ? 8 : 0 }}>
                <Card title="Weather Based Activity" className={classes.activityCard}>
                  <Spin spinning={loadingWeather} size="large">
                    <Row>
                      <Col xs={24}>
                        <ChartBesttimeLine data={weatherTemp} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Temperature" labels={LABELS_TEMP} />
                      </Col>
                      <Col xs={24}>
                        <ChartWindDirection data={weatherWindDir} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Wind Direction" labels={LABELS_WIND_DIR} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttimeLine data={weatherWindSpeed} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Wind Speed" labels={LABELS_WIND_SPEED} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttime data={weatherMoon} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Moon Phase" labels={LABELS_MOON} />
                      </Col>
                      <Col xs={24}>
                        <ChartBesttimeLine data={weatherPressure} color={Res.categoryColors[capitalizeFirstLetter(filters.category)]} color_alpha={Res.categoryColorsAlpha[capitalizeFirstLetter(filters.category)]} label="Barometric Pressure" labels={LABELS_PRESSURE} />
                      </Col>
                    </Row>
                  </Spin>
                </Card>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ActivityPage;
