import React, { useEffect, useState } from 'react';
import { Battery0Bar, Battery2Bar, Battery4Bar, Battery6Bar, BatteryFull } from '@mui/icons-material';
import batteryVoltage from '../../../assets/image/icons/battery_voltage_icon.png';
import externalVoltage from '../../../assets/image/icons/external_voltage_icon.png';

function BatteryComponentMC2({ battery1, battery2 }) {
  return (
    <div style={{ display: 'inline-flex', verticalAlign: 'top', color: 'gray' }}>
      <div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
        <div style={{ marginRight: '5px', marginLeft: '5px', textAlign: 'center' }}>
          <img src={batteryVoltage} alt="" height="40px" />
          <br />
          <div style={{ color: parseFloat(battery1) < 5 ? 'red' : '' }}>{battery1}%</div>
        </div>
        <div style={{ marginRight: '5px', marginLeft: '5px', textAlign: 'center' }}>
          <img src={externalVoltage} alt="" height="40px" />
          <br />
          <div>{battery2}V</div>
        </div>
      </div>
    </div>
  );
}

export default BatteryComponentMC2;
