import { Alert, Form, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import CameraRemoteMiniAdvanced from './advanced';
import CameraRemoteMiniBasic from './basic';
import { optionsMini2 } from './options';
import CameraRemoteMiniReport from './report';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const CameraRemoteMini = (props) => {
  const [form] = Form.useForm();
  const { md } = useBreakpoint();
  const { auth: { profile } } = useAuth();
  const [loading, setLoading] = useState(false);
  const [settingBasic, setSettingBasic] = useState<any>(null);
  const [settingAdvanced, setSettingAdvanced] = useState<any>(null);
  const [deviceSetting, setDeviceSetting] = useState({
    camera_mode: '',
    burst_mode: '',
    motion_sensitivity: '',
    picture_size: '',
    camera_check_int: '',
    sending_mode: '',
    night_mode: '',
    ir_flash: '',
    delay: '',
    camera_name: '',
    time_lapse: '',
    work_timer1: '',
    work_timer2: '',
    sd_card: 0,
    sd_card_max: 0,
    trans_video: '',
    video_quality: '',
    video_length: '',
    estimated_update_at: 0,
  });

  useEffect(() => {
    const { device } = props;
    console.log('device', device);
    if (device) {
      const newSettingBasic = {
        camera_mode: device.camera_mode,
        multi_shot: device.multi_shot,
        pir_sensitivity: device.pir_sensitivity,
        picture_size: device.picture_size,
        sms_remote: device.sms_remote,
        trans_video: device.trans_video,
        video_quality: device.video_quality,
        video_length: device.video_length,
        delay: device.delay,
        camera_name: device.camera_name,
      };
      setSettingBasic(newSettingBasic);
      const newSettingAdvanced = {
        sending_mode: device.sending_mode,
        night_mode: device.night_mode,
        ir_flash: device.ir_flash,
        time_lapse: device.time_lapse,
        work_timer1: device.work_timer1,
        work_timer2: device.work_timer2,
      };
      setSettingAdvanced(newSettingAdvanced);
      http_load_device_setting(props.camera.IMEI);
    }
  }, [props.device]);

  const getLabel = (value, options) => {
    for (let i = 0; i < options.length; i++) {
      if (parseInt(value, 10) == options[i].value) {
        return options[i].label;
      }
    }
    return '';
  };

  const http_load_device_setting = (IMEI) => {
    Api.REMOTE_CONTROL_DEVICE_SETTING(IMEI).then(((data: any) => {
      const res = JSON.parse(data.text);
      console.log(res);
      if (res.result == 'OK') {
        const new_device = {
          camera_mode: getLabel(res.device.camera_mode, optionsMini2.CameraMode),
          burst_mode: getLabel(res.device.multi_shot, optionsMini2.MultiShot),
          motion_sensitivity: getLabel(res.device.pir_sensitivity, optionsMini2.PirSensitivity),
          picture_size: getLabel(res.device.picture_size, optionsMini2.PictureSize),
          camera_check_int: getLabel(res.device.sms_remote, optionsMini2.Cellular),
          sending_mode: getLabel(res.device.sending_mode, optionsMini2.SendingMode),
          night_mode: getLabel(res.device.night_mode, optionsMini2.NightMode),
          ir_flash: getLabel(res.device.ir_flash, optionsMini2.IRFlash),
          trans_video: getLabel(res.device.trans_video, optionsMini2.VideoSending),
          video_quality: getLabel(res.device.video_quality, optionsMini2.VideoSize),
          video_length: getLabel(res.device.video_length, optionsMini2.VideoLength),
          delay: res.device.delay,
          camera_name: res.device.camera_name,
          time_lapse: res.device.time_lapse,
          work_timer1: res.device.work_timer1,
          work_timer2: res.device.work_timer2,
          sd_card: res.device.sd_card,
          sd_card_max: res.device.sd_card_max,
          estimated_update_at: res.device.estimated_update_at,
        };
        setDeviceSetting(new_device);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };
  const onChangeAdvanced = (values) => {
    console.log('onChangeAdvanced', values);
    setSettingAdvanced(values);
    http_update_device_setting(settingBasic, values);
  };

  const onChangeBasic = (values) => {
    console.log('onChangeBasic', values);
    setSettingBasic(values);
    http_update_device_setting(values, settingAdvanced);
  };

  const http_update_device_setting = (basic, advanced) => {
    const different_settings = { IMEI: props.camera.IMEI, ...basic, ...advanced };
    console.log('http_update_device_setting', different_settings);

    setLoading(true);
    Api.REMOTE_CONTROL_MINI_D404(different_settings).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: 'Your new settings have been sent to your camera and will take effect the next time your camera connects to the server.',
      });
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  return (
    <>
      {deviceSetting.estimated_update_at * 1000 > Date.now() && (
        <Alert
          message=""
          description={`Your firmware update request has been sent to the camera and should be completed by ${new Date(deviceSetting.estimated_update_at * 1000).toLocaleString()}. Please check back at that time.`}
          type="warning"
          closable
        />
      )}
      <CameraRemoteMiniBasic IMEI={props.camera.IMEI} setting={settingBasic} onChange={onChangeBasic} />
      <CameraRemoteMiniAdvanced IMEI={props.camera.IMEI} setting={settingAdvanced} onChange={onChangeAdvanced} deviceSetting={deviceSetting} isLastFirmware={props.device.isLastFirmware} />
      <CameraRemoteMiniReport camera={props.camera} device={props.device} deviceSetting={deviceSetting} />
    </>
  );
};

export default CameraRemoteMini;
