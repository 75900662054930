import { Button, Form, Input, notification, Select, Spin } from 'antd';
import * as Api from 'api';
import React, { useEffect, useState } from 'react';
import useAuth from 'redux/auth/auth.hook';
import PhoneInput from 'react-phone-input-2';
import { useHistory } from 'react-router-dom';
import { PATHS } from 'res/routes';
import { DollarOutlined } from '@ant-design/icons';

import classes from './style.module.scss';

export interface AccountType {
  id: number,
  name: string,
  email: string,
  password: string,
  firstname: string,
  lastname: string,
  country: string,
  state: string,
  city: string,
  zipcode: string,
  phonenumber: string,
  customer_id: string,
}

const { Option } = Select;
const three_countries = [
  {
    isoCode: 'US',
    name: 'United States',
  },
  {
    isoCode: 'CA',
    name: 'Canada',
  },
  {
    isoCode: 'MX',
    name: 'Mexico',
  },
];

const ProfileAccount = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { auth: { profile } } = useAuth();
  const [states, setStates] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const [account, setAccount] = useState<any>({});

  useEffect(() => {
    if (profile) {
      Api.PROFILE_GET_USER().then(((res: any) => {
        console.log(res.text);
        const data = JSON.parse(res.text);
        setAccount(data);
        form.setFieldsValue(data);
      })).catch((error: any) => {
        console.log('error ===>', error);
      });
    }
  }, [profile]);

  // useEffect(() => {
  //   if (account.subscribe_id == '' && !(account.account_type == 'Developer' || account.account_type == 'VIP' || account.account_type == 'Ranch')) {
  //     const path = PATHS.ACCOUNT_MANAGEMENT;
  //     history.push(path);
  //   }
  // }, [account]);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 4,
    },
  };

  const onWiseEye = () => {
    Api.PROFILE_GET_WP_TOKEN().then(((res: any) => {
      console.log(res.text);
      const data = JSON.parse(res.text);
      let url = 'https://wiseeyetech.com/my-account';
      if (data.result == 'OK') {
        url = `https://wiseeyetech.com/my-account?token_user=${data.data}`;
      }
      window.location.href = url;
      // const win = window.open(encodeURI(url), '_blank');
      // history.push(url);
      // const windowReference = window.open();
      // if (windowReference) {
      //   windowReference.location = url;
      //   windowReference.focus();
      // }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  return (
    <div>
      <Spin spinning={loading} size="large">
        <div className={classes.profileItem}>
          <Form
            {...layout}
            name="basic"
            form={form}
          >
            <Form.Item
              label="Username"
              name="name"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label="Display Name"
              name="jwt_name"
            >
              <Input disabled />
            </Form.Item>
            <div className={classes.divButton}>
              <Button type="primary" onClick={onWiseEye}>Setting</Button>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default ProfileAccount;
