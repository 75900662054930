import { QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Card, Col, Collapse, Divider, Form, Input, Row, Select, Space, Switch, TimePicker, Tooltip, notification } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import * as Api from 'api';
import AttComponent from 'components/camera/cameraSimbols/AttComponent';
import BatteryComponent from 'components/camera/cameraSimbols/BatteryComponent';
import CameraVersion from 'components/camera/cameraSimbols/CameraVersion';
import SignalComponent from 'components/camera/cameraSimbols/SignalComponent';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { optionsBig } from './options';
import classes from './style.module.scss';
import CameraRemoteBigBasic from './basic';
import CameraRemoteBigAdvanced from './advanced';
import CameraRemoteMiniBigReport from './report';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const format = 'HH:mm';

const CameraRemoteBig = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [settingBasic, setSettingBasic] = useState<any>(null);
  const [settingAdvanced, setSettingAdvanced] = useState<any>(null);
  const { md } = useBreakpoint();
  const [switchTimeLapse, setSwitchTimeLapse] = useState(true);
  const [burstModeEnabled, setBurstModeEnabled] = useState(true);
  const [workTimer1, setWorkTimer1] = useState(true);
  const [workTimer2, setWorkTimer2] = useState(true);
  const [deviceSetting, setDeviceSetting] = useState({
    camera_mode: '',
    burst_mode: '',
    motion_sensitivity: '',
    picture_size: '',
    camera_check_int: '',
    night_mode: '',
    delay: '',
    camera_name: '',
    time_lapse: '',
    work_timer1: '',
    work_timer2: '',
    sd_card: 0,
    sd_card_max: 0,
  });

  useEffect(() => {
    const { device } = props;
    // console.log('device', device);
    if (device) {
      const newSettingBasic = {
        camera_mode: device.camera_mode,
        multi_shot: device.multi_shot,
        pir_sensitivity: device.pir_sensitivity,
        picture_size: device.picture_size,
        sms_remote: device.sms_remote,
        delay: device.delay,
      };
      setSettingBasic(newSettingBasic);
      const newSettingAdvanced = {
        night_mode: device.night_mode,
        time_lapse: device.time_lapse,
        work_timer1: device.work_timer1,
        work_timer2: device.work_timer2,
      };
      setSettingAdvanced(newSettingAdvanced);
      http_load_device_setting(props.camera.IMEI);
    }
  }, [props.device]);

  useEffect(() => {
    console.log('camera', props.device);

    if (props.device.camera_mode == 2) {
      setBurstModeEnabled(false);
      form.setFieldsValue({ multi_shot: 1 });
    } else {
      setBurstModeEnabled(true);
    }

    let lapse = props.device.time_lapse;
    if (lapse == 'OFF') {
      setSwitchTimeLapse(false);
      lapse = '00:00:00';
    } else {
      setSwitchTimeLapse(true);
    }

    let work_timer1 = [moment('00:00', format), moment('00:00', format)];
    if (props.device.work_timer1 == 'OFF') {
      setWorkTimer1(false);
    } else {
      setWorkTimer1(true);
      const work_time = props.device.work_timer1.split('-');
      work_timer1 = [moment(work_time[0], format), moment(work_time[1], format)];
    }

    let work_timer2 = [moment('00:00', format), moment('00:00', format)];
    if (props.device.work_timer2 == 'OFF') {
      setWorkTimer2(false);
    } else {
      setWorkTimer2(true);
      const work_time = props.device.work_timer2.split('-');
      work_timer2 = [moment(work_time[0], format), moment(work_time[1], format)];
    }
    form.setFieldsValue({ ...props.device, delayMoment: moment(props.device.delay ? props.device.delay : '00:00:00', 'HH:mm:ss'), timeLapseMoment: moment(lapse, 'HH:mm:ss'), workTimerMoment1: work_timer1, workTimerMoment2: work_timer2 });
    http_load_device_setting(props.camera.IMEI);
  }, [props.device]);

  const onChangeAdvanced = (values) => {
    console.log('onChangeAdvanced', values);
    setSettingAdvanced(values);
    http_update_device_setting(settingBasic, values);
  };

  const onChangeBasic = (values) => {
    console.log('onChangeBasic', values);
    setSettingBasic(values);
    http_update_device_setting(values, settingAdvanced);
  };

  const http_update_device_setting = (basic, advanced) => {
    const different_settings = { IMEI: props.camera.IMEI, ...basic, ...advanced };
    console.log('http_update_device_setting', different_settings);

    setLoading(true);
    Api.DC2_SEND_SETTINGS(different_settings).then(((res: any) => {
      setLoading(false);
      notification.open({
        message: 'Your new settings have been sent to your camera and will take effect the next time your camera connects to the server.',
      });
    })).catch((error: any) => {
      setLoading(false);
      console.log('error ===>', error);
    });
  };

  const onFinish = (values) => {
    let delay = '00:00:10';
    if (values.delayMoment) { delay = values.delayMoment.format('HH:mm:ss'); }
    if (delay < '00:00:10') {
      delay = '00:00:10';
      form.setFieldsValue({ delayMoment: moment(delay, 'HH:mm:ss') });
    }

    let time_lapse = 'OFF';
    if (switchTimeLapse) {
      time_lapse = '00:00:00';
      if (values.timeLapseMoment) { time_lapse = values.timeLapseMoment.format('HH:mm:ss'); }
      if (time_lapse < '00:00:03') {
        time_lapse = '00:00:03';
        form.setFieldsValue({ timeLapseMoment: moment(time_lapse, 'HH:mm:ss') });
      }
    }
    let work_timer1 = 'OFF';
    if (workTimer1) {
      work_timer1 = '00:00-00:00';
      if (values.workTimerMoment1) {
        const time1 = values.workTimerMoment1[0].format(format);
        const time2 = values.workTimerMoment1[1].format(format);
        work_timer1 = `${time1}-${time2}`;
      }
    }

    let work_timer2 = 'OFF';
    if (workTimer2) {
      work_timer2 = '00:00-00:00';
      if (values.workTimerMoment2) {
        const time1 = values.workTimerMoment2[0].format(format);
        const time2 = values.workTimerMoment2[1].format(format);
        work_timer2 = `${time1}-${time2}`;
      }
    }

    if (values.camera_mode == 2) {
      form.setFieldsValue({ multi_shot: 1 });
      values.multi_shot = 1;
    }

    console.log(values);
    const params = {
      IMEI: props.device.IMEI,
      multi_shot: values.multi_shot,
      pir_sensitivity: values.pir_sensitivity,
      picture_size: values.picture_size,
      sms_remote: values.sms_remote,
      delay,
      time_lapse,
      camera_mode: values.camera_mode,
      night_mode: values.night_mode,
      work_timer1,
      work_timer2,
    };
    Api.REMOTE_CONTROL_STANDARD(params).then(((res: any) => {
      notification.open({
        message: 'Your new settings have been sent to your camera and will take effect the next time your camera connects to the server.',
      });
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
    setSwitchTimeLapse(checked);
    if (checked) {
      form.setFieldsValue({ timeLapseMoment: moment('00:00:03', 'HH:mm:ss') });
    }
  };

  const onChangeCameraMode = (value) => {
    console.log(value);
    if (value == 2) {
      setBurstModeEnabled(false);
      form.setFieldsValue({ multi_shot: 1 });
    } else {
      setBurstModeEnabled(true);
    }
  };

  const onGetPicture = () => {
    const params = {
      IMEI: props.device.IMEI,
    };
    Api.REMOTE_CONTROL_GETAPICKTURE_BIG(params).then((res: any) => {
      notification.open({
        message: res.text,
      });
    }).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const getLabel = (value, options) => {
    for (let i = 0; i < options.length; i++) {
      if (parseInt(value, 10) == options[i].value) {
        return options[i].label;
      }
    }
    return '';
  };

  const http_load_device_setting = (IMEI) => {
    Api.REMOTE_CONTROL_DEVICE_SETTING(IMEI).then(((data: any) => {
      const res = JSON.parse(data.text);
      console.log(res);
      if (res.result == 'OK') {
        const new_device = {
          camera_mode: getLabel(res.device.camera_mode, optionsBig.CameraMode),
          burst_mode: getLabel(res.device.multi_shot, optionsBig.MultiShot),
          motion_sensitivity: getLabel(res.device.pir_sensitivity, optionsBig.PirSensitivity),
          picture_size: getLabel(res.device.picture_size, optionsBig.PictureSize),
          camera_check_int: getLabel(res.device.sms_remote, optionsBig.Cellular),
          night_mode: getLabel(res.device.night_mode, optionsBig.NightMode),
          delay: res.device.delay,
          camera_name: res.device.camera_name,
          time_lapse: res.device.time_lapse,
          work_timer1: res.device.work_timer1,
          work_timer2: res.device.work_timer2,
          sd_card: res.device.sd_card,
          sd_card_max: res.device.sd_card_max,
        };
        setDeviceSetting(new_device);
      }
    })).catch((error: any) => {
      console.log('error ===>', error);
    });
  };

  const disabledSeconds = (hour: number, minute: number) => {
    if (hour == 0 && minute == 0) {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    }
    return [];
  };

  return (
    <>
      <CameraRemoteBigBasic IMEI={props.camera.IMEI} setting={settingBasic} onChange={onChangeBasic} />
      <CameraRemoteBigAdvanced setting={settingAdvanced} onChange={onChangeAdvanced} />
      <CameraRemoteMiniBigReport camera={props.camera} device={props.device} deviceSetting={deviceSetting} />
    </>
  );
};

export default CameraRemoteBig;
