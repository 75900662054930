import { CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Col, Drawer, Grid, Row, Spin } from 'antd';
import * as Api from 'api';
import ChartBesttime from 'components/activity/chartBesttime';
import FilterBestTime from 'components/activity/filterBestTime';
import ChartBesttimeLine from 'components/activity/chartBesttimeLine';
import React, { useEffect, useState } from 'react';
import useApi from 'redux/api/api.hook';
import useAuth from 'redux/auth/auth.hook';
import * as Res from 'res';
import classes from './style.module.scss';

const { useBreakpoint } = Grid;

const BestTimePage = () => {
  useEffect(() => {
    console.log('BestTimePage');
  }, []);
  const { showLeftFilter, setShowLeftFilter } = useApi();
  const { md, xs } = useBreakpoint();
  const [loadingBesttime, setLoadingBesttime] = useState(false);

  const [activity, setActivity] = useState<any>();
  const categories = ['deer', 'hog', 'turkey', 'people', 'vehicle', 'predator', 'other'];

  const handleFilters = (filters) => {
    console.log('filter => ', filters);
    setLoadingBesttime(true);
    const cameras = filters.camera_id == 0 ? [] : [filters.camera_id];
    const tags = filters.tag == '' ? [] : [filters.tag];
    Api.CHART_BESTTIME({ cameras, fromDate: filters.startDate, toDate: filters.endDate, tags, categories: [] }).then(((res: any) => {
      const result = JSON.parse(res.text);
      console.log('CHART_BESTTIME', result);
      setActivity(result);
      setLoadingBesttime(false);
    })).catch((error: any) => {
      console.log('error ===>', error);
      setLoadingBesttime(false);
    });
  };

  const handleRightFilter = (willShow) => {
    setShowLeftFilter(willShow);
  };

  useEffect(() => {
    console.log('md', md);
  }, [md]);

  return (
    <>
      <Row>
        {md && (
          <Col md={8} lg={6} xxl={4}>
            <div className={classes.imageFilter}>
              <FilterBestTime
                handleFilters={(arg) => handleFilters(arg)}
              />
            </div>
          </Col>
        )}
        {md == false && (
          <Drawer
            title="Filter"
            width={300}
            onClose={() => handleRightFilter(false)}
            visible={showLeftFilter}
            zIndex={900}
            handler={
              (
                <div className={classes.drawerHandle}>
                  {
                    showLeftFilter ? <CloseOutlined onClick={() => handleRightFilter(false)} /> : <FilterOutlined onClick={() => handleRightFilter(true)} />
                  }
                </div>
              )
            }
          >
            <FilterBestTime
              handleFilters={(arg) => handleFilters(arg)}
            />
          </Drawer>
        )}
        <Col md={16} xs={24} lg={18} xxl={20}>
          <div className={classes.content} style={{ padding: md ? 16 : 0 }}>
            <Spin spinning={loadingBesttime} size="large">
              <Row>
                {activity && categories.map((key) => (
                  <Col key={key} xs={24} md={24} lg={12} xxl={8} className={classes.chartCol}>
                    <ChartBesttimeLine data={activity.result[key]} color={Res.colorBestTime} color_alpha={Res.colorBestTime_alpha} label={key.charAt(0).toUpperCase() + key.slice(1)} labels={activity.key} />
                  </Col>
                ))}
              </Row>
            </Spin>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BestTimePage;
