import React, { useEffect, useState } from 'react';
import { SignalCellular0Bar, SignalCellular1Bar, SignalCellular2Bar, SignalCellular3Bar, SignalCellular4Bar } from '@mui/icons-material';

export default function SignalComponentMC2({ csq_percent, fontSize }) {
  function getSignalIcon() {
    if (csq_percent >= 100) {
      return <SignalCellular4Bar fontSize={fontSize} />;
    } if (csq_percent >= 60) {
      return <SignalCellular3Bar fontSize={fontSize} />;
    } if (csq_percent >= 40) {
      return <SignalCellular2Bar fontSize={fontSize} />;
    } if (csq_percent >= 20) {
      return <SignalCellular1Bar fontSize={fontSize} />;
    }
    return <SignalCellular0Bar fontSize={fontSize} />;
  }
  return (
    <div style={{ display: 'inline-flex', verticalAlign: 'top' }}>
      <div style={{ marginRight: '5px', marginLeft: '5px', textAlign: 'center' }}>
        {getSignalIcon()}
        <br />
        {/* <div style={{ color: 'gray' }}>{`${csq_percent}%`}</div> */}
      </div>
    </div>
  );
}
